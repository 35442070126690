$nav-height: 52px;

$viewport-boundary-small: 480px;
$viewport-boundary-middle: 768px;
$viewport_boundary-large: 1280px;

@mixin pc {
  @media screen and (min-width: $viewport-boundary-middle + 1px) {
    @content;
  }
}

@mixin pc-narrow {
  @media screen and (min-width: $viewport-boundary-middle + 1px) and (max-width: $viewport-boundary-large - 1px) {
    @content;
  }
}

@mixin mobile-tablet {
  @media screen and (max-width: $viewport-boundary-middle) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $viewport-boundary-middle) and (min-width: $viewport-boundary-small + 1px) {
    @content;
  }
}

@mixin link {
  text-decoration: none;
  transition: opacity 150ms ease;

  &:hover {
    text-decoration: none;
    opacity: 0.6;
  }
}
