@import "./mixins.scss";

.main-products {
  overflow: hidden;
  @include pc {
    margin-top: -100px;
    padding-top: 100px;
  }
}

#products-index {
  .layer-top {
    z-index: 2;
  }
  .layer-middle {
    z-index: 1;
  }
  .layer-bottom {
    z-index: -1;
  }

  @include pc {
    p {
      margin: 0;
    }
  }

  @include mobile-tablet {
    max-width: 375px;
  }
}

#products-decorations {
  position: relative;
  height: 0;

  .bar-wrapper {
    position: absolute;
    width: 227px;
    height: 535px;

    @include pc {
      top: -100px;
      right: 160px;
    }
    @include mobile-tablet {
      top: -300px;
      left: 120px;
    }
  }
  .bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 227px;
    height: 535px;
  }
  .bg-wrapper {
    position: absolute;
    top: 1000px;
    left: 0;
    width: 131px;
    height: 982px;
  }
}

#products-top {
  position: relative;

  @include pc {
    height: 355px;
    .lead {
      padding-bottom: 0;
    }
    .text-block {
      margin: 38px auto 0;
      width: 440px;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.87;
      letter-spacing: 2px;
      text-align: center;
      color: #45494b;
      word-break: keep-all;
    }
  }
  @include mobile-tablet {
    margin-bottom: 50px;
    .text-block {
      margin-left: 20px;
      margin-right: 20px;
      max-width: 335.2px;
      height: 112px;
      font-weight: 600;
      font-size: 14px;
      line-height: 2.0;
      letter-spacing: 1px;
      color: #45494b;
    }
  }
}

#products-visit,
#products-people,
#products-engagement{
  position: relative;

  @include pc {
    .title {
      margin-bottom: 40px;
    }
    .title-sub {
      font-family: Poppins, sans-serif;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
    }

    .description-main {
      margin: 0 0 16px;
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: 1.4px;
      color: rgba(0, 0, 0, 0.7);
    }
    .description-sub {
      font-weight: 400;
      font-size: 13px;
      line-height: 1.85;
      letter-spacing: 0.9px;
      color: rgba(0, 0, 0, 0.6);
    }

    .button-block {
      margin-top: 80px;
      display: flex;
    }

    .btn:hover {
      border: none;
    }

    .btn-admin {
      margin-left: 15px;
    }
  }

  @include mobile-tablet {
    .text-block {
      width: 90%;
      margin: 0 auto;
    }
    .back {
      z-index: -1;
    }
    .btn-admin {
      margin-top: 16px;
    }
  }
}

#products-visit {
  @include pc {
    height: 720px;
    margin-bottom: 91px;

    .text-block {
      position: absolute;
      top: 128px;
      right: 160px;
      @media screen and (min-width: 980px + 1px) and (max-width: 1280px) {
        right: 80px;
      }
      @media screen and (min-width: 768px + 1px) and (max-width: 980px) {
        right: 40px;
      }
      width: 410px;

      .title-main {
        height: 30px;
      }

      .description-sub {
        width: 410px;
      }

      .btn:hover {
        background-image: linear-gradient(99deg, #1e7dde, #00c4c4);
      }
    }
    .image-block {
      position: absolute;
      top: 0px;
      right: 1280px;
      @media screen and (min-width: 980px + 1px) and (max-width: 1280px) {
        right: 1190px;
      }
      @media screen and (min-width: 768px + 1px) and (max-width: 980px) {
        right: 1100px;
      }
      .front {
        position: absolute;
        top: 83px;
        left: -200px;
        width: 827px;
      }
      .back {
        position: absolute;
        top: 0px;
        left: -57px;
        width: 697px;
        height: 633px;
      }
    }
  }
  @include mobile-tablet {
    padding-top: 70px;
    .text-block {
      .title {
        text-align: center;
        margin-bottom: 390px;
        .title-sub {
          font-family: Poppins, sans-serif;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .description {
        margin-left: 18px;
        margin-right: 18px;
        max-width: 325px;
        text-align: left;
        .description-main {
          font-weight: 600;
          font-size: 18px;
          line-height: 1.33;
          letter-spacing: 1.4px;
          color: rgba(0, 0, 0, 0.7);
        }
        .description-sub {
          font-weight: 400;
          font-size: 13px;
          line-height: 1.69;
          letter-spacing: 0.9px;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .button-block {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 50px;
        .btn {
          margin: 0 auto;
          background-image: linear-gradient(99deg, #1e7dde, #00c4c4);
        }
        .btn-admin {
          background: #fff;
          margin-top: 16px;
          position: relative;
          &:after {
            position: absolute;
            top: -1px; bottom: -1px;
            left: -1px; right: -1px;
            background-image: linear-gradient(99deg, #1e7dde, #00c4c4);
            content: '';
            z-index: -1;
            border-radius: 100px;
          }
        }
      }
    }
    .image-block {
      $mobile-image-ratio: 0.42;
      .front {
        position: absolute;
        top: 230px;
        right: 61px;
        width: 351px;
        height: 237px;
      }
      .back {
        position: absolute;
        top: 125px;
        right: -58px;
        width: 840px * $mobile-image-ratio;
        height: 840px * $mobile-image-ratio;
      }
    }
  }
}

#products-people {
  @include pc {
    height: 662px;
    margin-bottom: 47px;

    .text-block {
      position: absolute;
      top: 120px;
      left: 160px;
      @media screen and (min-width: 980px + 1px) and (max-width: 1280px) {
        left: 80px;
      }
      @media screen and (min-width: 768px + 1px) and (max-width: 980px) {
        left: 40px;
      }
      width: 410px;

      .title-main {
        height: 30px;
      }

      .description-sub {
        width: 350px;
      }

      .btn:hover {
        background-image: linear-gradient(to right, #08a7c2, #5173fa 40%, #7d4cf3 78%, #923af0);
      }
    }
    .image-block {
      position: absolute;
      top: 0px;
      left: 1280px;
      @media screen and (min-width: 980px + 1px) and (max-width: 1280px) {
        left: 1190px;
      }
      @media screen and (min-width: 768px + 1px) and (max-width: 980px) {
        left: 1100px;
      }

      .front {
        position: absolute;
        top: 60px;
        right: -40px;
        width: 686px;
        height: 463px;
      }
      .back {
        position: absolute;
        top: 0px;
        right: -100px;
        width: 615px;
        height: 615px;
      }
    }
  }
  @include mobile-tablet {
    padding-top: 70px;
    .text-block {
      .title {
        text-align: center;
        margin-bottom: 370px;
        .title-sub {
          font-family: Poppins, sans-serif;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .description {
        margin-left: 18px;
        margin-right: 18px;
        max-width: 325px;
        text-align: left;
        .description-main {
          font-weight: 600;
          font-size: 18px;
          line-height: 1.33;
          letter-spacing: 1.4px;
          color: rgba(0, 0, 0, 0.7);
        }
        .description-sub {
          font-weight: 400;
          font-size: 13px;
          line-height: 1.69;
          letter-spacing: 0.9px;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .button-block {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 50px;
        .btn {
          margin: 0 auto;
          background-image: linear-gradient(to right, #08a7c2, #5173fa 40%, #7d4cf3 78%, #923af0);
        }
        .btn-admin {
          background: #fff;
          margin-top: 16px;
          position: relative;
          &:after {
            position: absolute;
            top: -1px; bottom: -1px;
            left: -1px; right: -1px;
            background-image: linear-gradient(to right, #08a7c2, #5173fa 40%, #7d4cf3 78%, #923af0);
            content: '';
            z-index: -1;
            border-radius: 100px;
          }
        }
      }
    }
    .image-block {
      .front {
        $mobile-image-ratio: 0.55;
        position: absolute;
        top: 195px;
        left: 32px;
        width: 686px * $mobile-image-ratio;;
        height: 463px * $mobile-image-ratio;;
      }
      .back {
        $mobile-image-ratio: 0.45;
        position: absolute;
        top: 135px;
        left: -130px;
        width: 780px * $mobile-image-ratio;;
        height: 780px * $mobile-image-ratio;;
      }
    }
  }
}

#products-engagement {
  @extend #products-visit;
  @include pc {
    .image-block {
      .front {
        position: absolute;
        left: -57px;
        width: 697px;
        height: auto;
        top: 0px;
      }
      .back {
        position: absolute;
        left: -57px;
        width: 697px;
        height: auto;
        top: 0px;
      }
    }
  }
  @include mobile-tablet {
    .image-block {
      $mobile-image-ratio: 0.42;
      .front {
        position: absolute;
        top: 125px;
        right: 0px;
        width: 840px * $mobile-image-ratio;
        height: auto;
      }
      .back {
        position: absolute;
        top: 125px;
        right: 0px;
        width: 840px * $mobile-image-ratio;
        height: auto;
      }
    }
  }
}
