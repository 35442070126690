@import "./mixins.scss";

$gray: rgba(0, 0, 0, 0.56);
$gold: #B3A16B;
$silver: #A9AFB3;
$bronze: #855A3D;

body#awards-2019 {
  position: relative;
  background-color: #F7F7F7;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  header.header {
    position: fixed;
    justify-content: flex-start;
    z-index: 100;
    width: 10px;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding-left: 30px;
    font-family: Poppins;

    @include mobile-tablet {
      background: none;
      height: 41px;
    }

    .logo {
      position: absolute;
      background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/awards2019/logo.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 167px;
      height: 24px;
      top: 32px;
      left: 24px;

      @include mobile-tablet {
        width: 140px;
        top: 16px;
        left: 16px;
      }

      & > a {
        display: block;
        width: 100%;
        height: 100%;
      }
      .link {
        width: 100%;
        height: 100%;
      }
    }

    ul.nav {
      transform: rotate(-90deg);
      transform-origin: left;
      margin-top: auto;

      @include mobile-tablet {
        visibility: hidden;
      }

      li {
        text-transform: uppercase;
        line-height: 44px;
        color: $gray;
        a {
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 1.2px;
          opacity: 0.7;
          &:hover{
            opacity: 0.86;
          }
        }
      }
    }
  }

  .scroll {
    background-color: none;
  }

  .multiply {
    mix-blend-mode: multiply;
    position: absolute;
    background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/awards2019/multiply.png');
    background-size: cover;
    transform: translate(-50%);
    width: 1381px;
    height: 373px;
    top: 527px;
    left: 592px;

    @include mobile-tablet {
      transform: rotate(90deg);
      transform-origin: left bottom;
      top : 166px;
      left: -110px;
    }
  }

  .bubble {
    position: absolute;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    & > .child {
      mix-blend-mode: multiply;
      position: relative;
      z-index: 1;
      border-radius: 50%;
      background-image: none;
    }
    &.bubble-0 {
      border-radius: unset;
      background-size: contain;
      background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/awards2019/oval-0.png');
      width: 408px;
      height: 156px;
      top : -106px;
      left: 132px;
      @include mobile-tablet {
        top: -191px;
        left: 48px;
      }
    }
    &.bubble-1 {
      background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/awards2019/oval-1.png');
      width: 760px;
      height: 760px;
      top : -55px;
      left: 358px;
      @include mobile-tablet {
        width: 380px;
        height: 380px;
        top: 13px;
        left: 0px;
      }
      & > .child {
        background-color: #60e1f7;
        width: 760px;
        height: 760px;
        top: -68px;
        left: 82px;
        @include mobile-tablet {
          width: 380px;
          height: 380px;
          top: -32px;
          left: 40px;
        }
      }
    }
    &.bubble-2 {
      background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/awards2019/oval-2.png');
      width: 520px;
      height: 520px;
      top: 178px;
      left: -184px;
      @include mobile-tablet {
        width: 349px;
        height: 349px;
        top: -452px;
        left: -62px;
      }
      & > .child {
        background-color: #fbf45b;
        width: 700px;
        height: 700px;
        top: -133px;
        left: -280px;
        @include mobile-tablet {
          width: 470px;
          height: 470px;
          top: -90px;
          left: -190px;
        }
      }
    }
    &.bubble-3 {
      background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/awards2019/oval-3.png');
      width: 460px;
      height: 460px;
      top: -1px;
      left: 378px;
      @include mobile-tablet {
        width: 231px;
        height: 231px;
        top: 66px;
        left: 112px;
      }
      & > .child {
        background-color: #ff9cde;
        width: 420px;
        height: 420px;
        top: 40px;
        left: -96px;
        @include mobile-tablet {
          width: 221px;
          height: 221px;
          top: -24px;
          left: -2px;
        }
      }
    }
    &.bubble-4 {
      background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/awards2019/oval-4.png');
      width: 294px;
      height: 294px;
      top: -36px;
      left: 528px;
      @include mobile-tablet {
        top: 252px;
        left: -50px;
      }
      & > .child {
        background-color: #d09bff;
        width: 294px;
        height: 294px;
        top: 0px;
        left: 98px;
      }
    }
  }
  .main {
    overflow: hidden;
    margin: 0 auto;
    .main-inner {
      max-width: 100%;
    }
    .img-coming-soon {
      background-repeat:  no-repeat;
      background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/awards2019/coming-soon.png');
      background-size: contain;
      height: 352px;
      width: 852px;
      margin-top: 22px;
      margin-left: 8px;

      @include mobile-tablet {
        max-width: 343px;
        width: unset;
        height: 130px;
        margin: 0px;
      }
    }

    .entry-wrapper {
      position: fixed;
      z-index: 99;
      left: 0;
      right: 0;
      bottom: 26px;
      margin: 0 auto;
      width: 312px;

      @include mobile-tablet {
        max-width: 375px;
        width: unset;
      }
    }
    .entry {
      border-radius: 26px;
      background-image: linear-gradient(75deg, #0d93e0, #00c4c4);
      width: 312px;
      height: 52px;
      margin: 0 auto;
      text-align: center;
      line-height: 52px;

      @include mobile-tablet {
        width: calc(100% - 32px);
      }

      a {
        display: block;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        color: white;

        &:hover {
          background-color: rgba(255, 255, 255, 0.06);
        }
        &:active {
          background-color: rgba(255, 255, 255, 0.12)
        }
      }
    }

    .eyecatch {
      background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/awards2019/rectangle.png');
      background-position: center;
      background-size: cover;
      height: 240px;
      @include mobile-tablet {
        height: 120px;
      }
    }

    section {
      position: relative;
      &.dark {
        background-color: black;
        color: white;
        & > .section-inner {
          padding-top: 80px;
          padding-bottom: 100px;

          @include mobile-tablet {
            padding-top: 64px;
            padding-bottom: 54px;
          }
        }
        & > .section-inner > .section-header > .section-header-inner > h3 {
          top: 312px;
          color: white;

          @include mobile-tablet {
            top: 0;
            left: 0;
          }

          &:before {
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .section-inner {
        position: relative;
        display: flex;
        width: 1040px;
        margin: 0 auto;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 60px;
        padding-right: 60px;

        @include mobile-tablet {
          flex-direction: column;
          width: auto;
          padding-top: 64px;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: 54px;
        }

        .section-body {
          position: relative;
          width: 768px;

          @include mobile-tablet {
            width: 100%;
          }
        }
        .section-header {
          width: 136px;

          @include mobile-tablet {
            width: 100%;
          }

          .section-header-inner {
            height: 360px;
            border-left: 1px solid;

            @include mobile-tablet {
              border-left: none;
              border-top: 1px solid;
              height: 80px;
            }

            h3 {
              position: absolute;
              width: 300px;
              height: 127px;
              top: 332px;
              left: 132px;
              margin: 0;
              padding-top: 8px;
              transform: rotate(-90deg);
              transform-origin: left;

              font-family: Poppins;
              font-size: 36px;
              font-weight: normal;
              text-align: right;
              line-height: 59px;
              letter-spacing: normal;
              color: rgba(0, 0, 0, 0.74);

              @include mobile-tablet {
                position: relative;
                transform: none;
                width: 100%;
                top: 0;
                left: 0;
                padding-top: 12px;
                text-align: left;
              }
              &:before {
                position: absolute;
                display: inline-block;
                top: 0;
                right: 0;
                padding-top: 8px;
                transform: rotate(0deg);

                font-family: Poppins;
                font-size: 12px;
                text-align: right;
                letter-spacing: 0.2pt;
                line-height: 0.1;
                color: rgba(0, 0, 0, 0.4);
                content: 'Wantedly VISIT AWARDS 2019';

                @include mobile-tablet {
                  top: 6px;
                  left: 0;
                  right: unset;
                  letter-spacing: 0.12pt;
                }
              }
            }
          }
        }
      }
      .body {
        max-width: 1600px;
        margin: 120px auto 0 auto;

        @include mobile-tablet {
          width: 343px;
          margin: 0 auto;
        }
      }
    }

    #intro {
      height: 900px;

      @include mobile-tablet {
        height: 985px;
      }

      .title-container {
        position: relative;
        @include mobile-tablet {
          max-width: 343px;
          width: 100%;
          margin: 0 auto;
        }
        font-family: Poppins;
        & > h2 {
          position: relative;
          background: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/awards2019/title.svg');
          background-repeat: no-repeat;
          background-size: contain;
          width: 261px;
          height: 87px;
          margin-top: 137px;
          margin-bottom: 31px;
          text-indent: -9000px;

          @include mobile-tablet {
            width: 100%;
            height: 115px;
            margin-top: 395px;
            margin-bottom: 15px;
          }
        }

        & > h3 {
          width: 0px;
          margin-top: 28px;
          margin-bottom: 4px;
          font-size: 36px;
          font-weight: normal;
          line-height: 1.12;
          color: $gray;

          @include mobile-tablet {
            margin-top: 0px;
          }
        }

        & > p {
          height: 24px;
          margin: 0;
          font-family: Poppins;
          font-size: 16px;

          @include mobile-tablet{
            display: inline-block;
            margin-top: 4px;
            font-size: 18px;
            line-height: 1.56;
          }
        }
      }
    }

    #about {
      margin-top: -346px;
      padding-bottom: 0px;

      @include mobile-tablet {
        margin-top: 0px;
        padding-top: 117px;
      }
      .body {
        width: 520px;
        margin-top: 220px;
        margin-left: 385px;

        @include mobile-tablet {
          width: auto;
          margin-top: 17px;
          margin-left: 0px;
        }

        br {
          line-height: 30px;
        }
        p {
          margin: 0;
          padding: 0;
          font-family: HiraginoSans-W6;
          font-size: 16px;
          line-height: 2;
          letter-spacing: 0.2px;
          color: rgba(0, 0, 0, 0.84);

          @include mobile-tablet {
            font-family: HiraginoSans-W6;
            font-size: 16px;
            line-height: 2;
            letter-spacing: 0.2px;
          }
        }
      }
    }
    #keynote {
      .speaker {
        position: relative;
        display: flex;
        @include mobile-tablet {
          width: 343px;
          margin: 0 auto;
        }

        .name {
          z-index: 0;
          padding-top: 55px;
          letter-spacing: 0.1em;

          @include mobile-tablet {
            padding-top: 0px;
          }

          .en {
            width: 0px;
            text-transform: uppercase;
            font-family: Poppins;
            font-size: 80px;
            font-weight: 600;
            line-height: 1.2;

            @include mobile-tablet {
              padding-top: 22px;
              font-size: 56px;
              line-height: 0.86;
              letter-spacing: normal;
            }
          }
          .ja {
            padding-top: 27px;
            font-family: HiraginoSans-W4;
            font-size: 48px;
            line-height: 18px;
            letter-spacing: 0.7px;

            @include mobile-tablet {
              margin-top: 0px;
              padding-top: 11px;
              font-size: 24px;
              letter-spacing: 0.5px;
            }
          }
        }
      }
      .body {
        margin-top: 184px;
        font-family: HiraginoSans-W6;
        font-size: 15px;
        line-height: 1.87;
        letter-spacing: normal;

        @include mobile-tablet {
          width: 100%;
          margin-top: 180px;
          font-family: HiraginoSans-W4;
        }

        p {
          margin: 0;
          padding: 0;
        }
      }
    }
    #awards {
      .section-inner {
        padding-bottom: 24px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style-type: none;
        @include mobile-tablet {
          margin-top: 24px;
        }

        li {
          box-sizing: border-box;
          border-left-color: rgba(0, 0, 0, 0.06);
          border-left-style: solid;
          border-left-width: 8px;
          width: calc(50% - 20px);
          height: 168px;
          margin-bottom: 36px;
          padding-left: 16px;
          color: rgba(0, 0, 0, 0.84);

          @include mobile-tablet {
            width: 100%;
            height: 168px;
            margin-bottom: 36px;
          }

          h4 {
            height: 48px;
            margin: 0;
            padding: 0;
            font-family: Poppins;
            font-size: 36px;
            font-weight: normal;
            letter-spacing: 1.3px;
            line-height: 1.17;
            color: rgba(0, 0, 0, 0.84);

            @include mobile-tablet {
              font-family: Poppins;
              font-size: 36px;
              line-height: 50px;
              letter-spacing: 1.3px;
            }
          }

          p {
            min-height: 96px;
            margin: 12px 0px 0px 4px;
            font-family: HiraginoSans-W6;
            font-size: 14px;
            letter-spacing: normal;
            line-height: 1.71;
            color: $gray;

            @include mobile-tablet {
              min-height: 56px;
              margin-top: -5px;
              margin-left: 2px;
              font-family: HiraginoSans-W6;
              font-size: 14px;
              line-height: 2;
              letter-spacing: 1px;
            }
          }
          &.top {
            height: 180px;
            h4 {
              font-size: 48px;
              height: 56px;
            }
            p {
              @include mobile-tablet {
                margin-top: 18px;
              }
            }
          }

          &.gold {
            width: 100%;
            border-left-color: $gold;
            @include mobile-tablet {
              min-height: 240px;
              height: unset;
            }
          }
          &.silver {
            border-left-color: $silver;
          }
          &.bronze {
            border-left-color: $bronze;
          }
        }
      }
    }

    #special {
      .section-body {

        .description {
          position: absolute;
          bottom: 0;
          @include mobile-tablet {
            position: relative;
            bottom: unset;
            margin-top: 24px;
          }

          font-family: HiraginoSans-W6;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.13;
          letter-spacing: 0.47px;
          color: #ffffff;

          p {
            margin: 0;
          }
        }
      }
    }

    #timetable {
      .section-body {
        @include mobile-tablet {
          padding-top: 24px;
        }
      }
      .open-close {
        display: flex;
        align-items: center;
        height: 56px;
        letter-spacing: normal;

        @include mobile-tablet {
          flex-direction: column;
          align-items: start;
          height: auto;
        }

        h4 {
          margin: 0;
          margin-right: -8px;
          font-family: Poppins;
          font-size: 48px;
          font-weight: normal;
          line-height: 1.17;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.84);

          @include mobile-tablet {
            width: auto;
            margin: 0;
            font-size: 36px;
            line-height: 1.56;
          }
        }

        .notice {
          margin: 0;
          margin-left: auto;
          font-family: HiraginoSans-W6;

          @include mobile-tablet {
            margin-top: -4px;
            margin-left: 0;
          }

          span {
            width: 500px;
            font-size: 12px;
            letter-spacing: .2px;
            line-height: 12px;
            color: rgba(0, 0, 0, 0.4);

            @include mobile-tablet {
              font-family: HiraginoSans-W4;
              font-size: 12px;
            }
          }
        }
      }
      ul {
        padding-top: 24px;
        padding-bottom: 24px;

        @include mobile-tablet {
          padding-top: 12px;
          padding-bottom: 12px;
        }

        li {
          display: flex;
          align-items: center;
          border-radius: 1px;
          background-color: rgba(0, 0, 0, 0.03);
          height: 56px;
          margin-top: 8px;
          padding: 0;

          &:first-child {
            margin-top: 0px;
          }

          @include mobile-tablet {
            width: auto;
          }

          h5 {
            width: 86px;
            margin: 14px 24px;
            font-family: Poppins;
            font-size: 20px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.4;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0.84);

            @include mobile-tablet {
              margin-left: 12px;
            }
          }
          span {
            font-family: HiraginoSans-W4;
            font-size: 15px;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0.56);
          }
        }
      }
    }

    #access {
      .section-inner {
        padding-top: 77px;
        padding-bottom: 82px;
        @include mobile-tablet {
          padding-top: 64px;
          padding-bottom: 346px;
        }

        .section-header-inner {
          height: 214px;;
          @include mobile-tablet {
            height: 100px;;
          }

        }

        .section-body {
          @include mobile-tablet {
            max-width: 343px;
            margin: 0 auto;
          }
        }
      }
      .place {
        width: 760px;
        align-items: center;
        @include mobile-tablet {
          width: 100%;
        }

        p {
          margin: 0;

          @include mobile-tablet {
            display: inline-block;
          }

          &.hall {
            height: 56px;
            font-family: Poppins;
            font-size: 48px;
            line-height: 1.17;
          }
          &.building {
            height: 36px;
            font-family: Poppins;
            font-size: 28px;
            line-height: 1.29;
          }
          &.address {
            margin-top: 14px;
            font-family: HiraginoSans-W4;
            font-size: 15px;
            line-height: 1.87;

            @include mobile-tablet {
              margin-top: 18px;
            }
          }

        }
      }
      .btn-wrapper {
        margin-top: 36px;
        margin-left: 4px;
        display: flex;

        @include mobile-tablet {
          margin-top: 20px;
        }
        .btn {
          @include mobile-tablet {
            background-image: none;
            background-color: white;
          }
          color: rgba(0, 0, 0, 0.56);
        }

        .btn:hover {
          transform: none;
          background-image: none;
          background-color: rgba(255, 255, 255, 0.97);
          color: rgba(0, 0, 0, 0.53);
        }

        .go-to-map-btn {
          display: flex;
          z-index: 1;
          justify-content: center;
          align-items: center;
          background-color: white;
          box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
          width: 128px;
          height: 36px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;

          @include mobile-tablet {
            width: 128px;
            height: 36px;
            font-size: 14px;
            text-align: center;
          }
        }
        .copy-btn {
          display: flex;
          z-index: 1;
          background-color: white;
          box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
          justify-content: center;
          align-items: center;
          border-radius: 81.8px;
          width: 36px;
          height: 36px;
          margin-left: 12px;

          @include mobile-tablet {
            border-radius: 100px;
            width: 36px;
            height: 36px;
          }

          i {
            color: rgba(0, 0, 0, 0.56);
            @include mobile-tablet {
              font-size: 18px;
            }
            @include mobile-tablet {
              color: rgba(0, 0, 0, 0.53);
            }
          }
        }
        .tooltip:after {
          background-color: white;
          width: 265px;

          @include mobile-tablet {
            left: -140px;
          }
        }
      }
    }
    .map-block {
      background-color: rgba(255, 255, 255, 0.8);
      margin: 0 auto;
      height: 450px;

      @include mobile-tablet {
        height: 375px;
      }
    }
  }
  footer {
    background-color: black;
    width: 100%;
    height: 52px;
  }
}
