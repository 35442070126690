@import "./mixins.scss";

#ir-news,
#ir-library,
#ir-public-notice,
#ir-meeting,
#home-section-ir {
  .entry {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  }
  .entry-date,
  .meeting-date {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    width: 90px;
    color: rgba(0, 0, 0, 0.4);
  }
  .entry-category-wrapper {
    display: flex;
  }
  .entry-category {
    width: 114px;
    height: 26px;
    text-align: center;
    font-size: 12px;
    color: white;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .entry-title,
  .meeting-title {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
  }

  @include pc {
    .entries-block {
      margin-bottom: 60px;
      padding: 0 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
    }
    .entries-inner {
      display: flex;
      flex-direction: column;
    }
    .entry {
      max-width: 940px;
      margin-bottom: 30px;
      padding: 0 30px 30px 0;
      display: flex;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .entry-date,
    .entry-category {
      flex-shrink: 0;
    }
    .entry-category,
    .meeting-date {
      margin-left: 15px;
    }
    .entry-title,
    .meeting-title {
      @include link;
      display: flex;
      margin-left: 30px;
      font-size: 15px;
      line-height: 1.5;
      i {
        display: block;
        margin-right: 12px;
      }
    }
    .entry-category-wrapper {
      i {
        display: none;
      }
    }
  }

  @include mobile-tablet {
    .entries-block {
      padding: 0 7%;
      margin-bottom: 60px;
    }
    .entries-inner {
      display: flex;
      flex-direction: column;
    }
    .entry {
      width: 100%;
      padding-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .entry-title,
    .meeting-title {
      display: block;
      font-size: 14px;
      line-height: 1.71;
      i {
        display: none;
      }
    }
    .entry-category-wrapper {
      i {
        display: block;
        margin-left: 15px;
      }
    }
    .meeting-icon {
      font-size: 18px;
      margin: 8px 0 20px;
    }
    .meeting-item {
      padding-top: 26px;
    }
  }
}

#ir-news,
#ir-public-notice,
#ir-library {
  .main-content {
    margin-bottom: 120px;
  }
  .select-box-container {
    margin-bottom: 80px;
  }
  .pagination-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 34px;
  }
  @include pc {
    .entry-category-wrapper {
      i {
        font-size: 18px;
      }
    }
  }
  @include mobile-tablet {
    .entry {
      margin-bottom: 25px;
    }
    .entry-category-wrapper {
      margin-top: 15px;
      i {
        font-size: 18px;
      }
    }
    .entry-title {
      margin-top: 20px;
    }
  }
}

#home-section-ir {
  .entry-category-wrapper {
    i {
      font-size: 18px;
    }
  }
  @include mobile-tablet {
    .entry {
      margin-bottom: 30px;
    }
    .entry-title {
      margin-top: 30px;
    }
    .entry-category-wrapper {
      margin-top: 10px;
    }
  }
}
