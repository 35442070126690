@font-face {
  font-family: "WtIcon";
  src: url('../fonts/WtIcon.eot');
  src: url('../fonts/WtIcon.eot?#iefix') format('eot'),
    url('../fonts/WtIcon.woff') format('woff'),
    url('../fonts/WtIcon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.wt-icon:before {
  display: inline-block;
  font-family: "WtIcon";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: text-bottom;
}

.wt-icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.wt-icon-2x { font-size: 2em; }
.wt-icon-3x { font-size: 3em; }
.wt-icon-4x { font-size: 4em; }
.wt-icon-5x { font-size: 5em; }
.wt-icon-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

$wt-icon-android: "\EA01";
$wt-icon-apple: "\EA02";
$wt-icon-arrow-down: "\EA03";
$wt-icon-at-mark-o-1: "\EA04";
$wt-icon-at-mark: "\EA05";
$wt-icon-b: "\EA06";
$wt-icon-balloon-o-1: "\EA07";
$wt-icon-balloon-o-2: "\EA08";
$wt-icon-balloon: "\EA09";
$wt-icon-balloons-o-1: "\EA0A";
$wt-icon-balloons-o-2: "\EA0B";
$wt-icon-balloons: "\EA0C";
$wt-icon-bell-o-1: "\EA0D";
$wt-icon-bell-o-2: "\EA0E";
$wt-icon-bell: "\EA0F";
$wt-icon-bers-o-1: "\EA10";
$wt-icon-bers: "\EA11";
$wt-icon-bookmark-o-1: "\EA12";
$wt-icon-bookmark-o-2: "\EA13";
$wt-icon-bookmark: "\EA14";
$wt-icon-business-bag-o-1: "\EA15";
$wt-icon-business-bag-o-2: "\EA16";
$wt-icon-business-bag: "\EA17";
$wt-icon-calendar-o-1: "\EA18";
$wt-icon-calendar: "\EA19";
$wt-icon-camera-o-2: "\EA1A";
$wt-icon-camera: "\EA1B";
$wt-icon-chavron-down-o-1: "\EA1C";
$wt-icon-chavron-down: "\EA1D";
$wt-icon-chavron-up: "\EA1E";
$wt-icon-check-circle: "\EA1F";
$wt-icon-check: "\EA20";
$wt-icon-chevron-left-o-1: "\EA21";
$wt-icon-chevron-left: "\EA22";
$wt-icon-chevron-right-o-1: "\EA23";
$wt-icon-chevron-right: "\EA24";
$wt-icon-chevron-up-o-1: "\EA25";
$wt-icon-circle: "\EA26";
$wt-icon-circles-o-1: "\EA27";
$wt-icon-circles: "\EA28";
$wt-icon-clock-o-2: "\EA29";
$wt-icon-clock: "\EA2A";
$wt-icon-close-circle-o-1: "\EA2B";
$wt-icon-close-circle-o-2: "\EA2C";
$wt-icon-close-circle: "\EA2D";
$wt-icon-close-o-1: "\EA2E";
$wt-icon-close: "\EA2F";
$wt-icon-code: "\EA30";
$wt-icon-company-o-1: "\EA31";
$wt-icon-company-o-2: "\EA32";
$wt-icon-company-plus-o-1: "\EA33";
$wt-icon-company-plus-o-2: "\EA34";
$wt-icon-company-plus: "\EA35";
$wt-icon-company: "\EA36";
$wt-icon-compass-o-1: "\EA37";
$wt-icon-compass-o-2: "\EA38";
$wt-icon-compass: "\EA39";
$wt-icon-copy-o-1: "\EA3A";
$wt-icon-copy: "\EA3B";
$wt-icon-crip-o-1: "\EA3C";
$wt-icon-crip: "\EA3D";
$wt-icon-dashboard: "\EA3E";
$wt-icon-door-o-1: "\EA3F";
$wt-icon-door-o-2: "\EA40";
$wt-icon-door: "\EA41";
$wt-icon-download-circle-o-2: "\EA42";
$wt-icon-download-circle: "\EA43";
$wt-icon-download-cloud-o-2: "\EA44";
$wt-icon-download-cloud: "\EA45";
$wt-icon-download: "\EA46";
$wt-icon-ellipsis-h-o-1: "\EA47";
$wt-icon-ellipsis-h: "\EA48";
$wt-icon-ellipsis-v-o-1: "\EA49";
$wt-icon-ellipsis-v: "\EA4A";
$wt-icon-exclamation-circle-o-1: "\EA4B";
$wt-icon-exclamation-circle-o-2: "\EA4C";
$wt-icon-exclamation-circle: "\EA4D";
$wt-icon-eyes: "\EA4E";
$wt-icon-facebook: "\EA4F";
$wt-icon-file-o-1: "\EA50";
$wt-icon-file-o-2: "\EA51";
$wt-icon-file: "\EA52";
$wt-icon-fire-o-1: "\EA53";
$wt-icon-fire: "\EA54";
$wt-icon-folder-o-1: "\EA55";
$wt-icon-folder-o-2: "\EA56";
$wt-icon-folder: "\EA57";
$wt-icon-gear-o-1: "\EA58";
$wt-icon-gear: "\EA59";
$wt-icon-github: "\EA5A";
$wt-icon-google: "\EA5B";
$wt-icon-h2: "\EA5C";
$wt-icon-h3: "\EA5D";
$wt-icon-hash: "\EA5E";
$wt-icon-hatena: "\EA5F";
$wt-icon-heart-o-2: "\EA60";
$wt-icon-heart: "\EA61";
$wt-icon-infomation-o-1: "\EA62";
$wt-icon-infomation-o-2: "\EA63";
$wt-icon-infomation: "\EA64";
$wt-icon-jump: "\EA65";
$wt-icon-label-text-o-1: "\EA66";
$wt-icon-label-text-o-2: "\EA67";
$wt-icon-label-text: "\EA68";
$wt-icon-line-chart-o-2: "\EA69";
$wt-icon-link: "\EA6A";
$wt-icon-linkedin: "\EA6B";
$wt-icon-list: "\EA6C";
$wt-icon-location: "\EA6D";
$wt-icon-mail-o-1: "\EA6E";
$wt-icon-mail-o-2: "\EA6F";
$wt-icon-mail: "\EA70";
$wt-icon-message-o-1: "\EA71";
$wt-icon-message-o-2: "\EA72";
$wt-icon-message: "\EA73";
$wt-icon-minus-o-1: "\EA74";
$wt-icon-minus: "\EA75";
$wt-icon-newspaper-o-1: "\EA76";
$wt-icon-newspaper-o-2: "\EA77";
$wt-icon-newspaper: "\EA78";
$wt-icon-pencil-o-1: "\EA79";
$wt-icon-pencil-paper-o-1: "\EA7A";
$wt-icon-pencil-paper: "\EA7B";
$wt-icon-pencil: "\EA7C";
$wt-icon-person-o-1: "\EA7D";
$wt-icon-person-o-2: "\EA7E";
$wt-icon-person-plus-o-1: "\EA7F";
$wt-icon-person-plus-o-2: "\EA80";
$wt-icon-person-plus: "\EA81";
$wt-icon-person: "\EA82";
$wt-icon-phone-o-1: "\EA83";
$wt-icon-phone: "\EA84";
$wt-icon-pin: "\EA85";
$wt-icon-plus-circle-o-1: "\EA86";
$wt-icon-plus-o-1: "\EA87";
$wt-icon-plus: "\EA88";
$wt-icon-quote: "\EA89";
$wt-icon-refresh-o-1: "\EA8A";
$wt-icon-refresh: "\EA8B";
$wt-icon-reply: "\EA8C";
$wt-icon-right-pane-o-1: "\EA8D";
$wt-icon-right-pane: "\EA8E";
$wt-icon-search-o-1: "\EA8F";
$wt-icon-search-short-o-1: "\EA90";
$wt-icon-search-short: "\EA91";
$wt-icon-search: "\EA92";
$wt-icon-separator: "\EA93";
$wt-icon-share-apple: "\EA94";
$wt-icon-share-google: "\EA95";
$wt-icon-share-ms: "\EA96";
$wt-icon-share: "\EA97";
$wt-icon-sliders: "\EA98";
$wt-icon-spanner-o-1: "\EA99";
$wt-icon-spanner: "\EA9A";
$wt-icon-star-o-1: "\EA9B";
$wt-icon-star: "\EA9C";
$wt-icon-support: "\EA9D";
$wt-icon-tag-o-1: "\EA9E";
$wt-icon-tag-o-2: "\EA9F";
$wt-icon-tag: "\EAA0";
$wt-icon-target: "\EAA1";
$wt-icon-text-square-o-1: "\EAA2";
$wt-icon-text-square-o-2: "\EAA3";
$wt-icon-text-square: "\EAA4";
$wt-icon-thumb-up-o-1: "\EAA5";
$wt-icon-thumb-up-o-2: "\EAA6";
$wt-icon-thumb-up: "\EAA7";
$wt-icon-trash-o-1: "\EAA8";
$wt-icon-trash-o-2: "\EAA9";
$wt-icon-trash: "\EAAA";
$wt-icon-trencher-o-1: "\EAAB";
$wt-icon-trencher-o-2: "\EAAC";
$wt-icon-trencher: "\EAAD";
$wt-icon-triangle-down: "\EAAE";
$wt-icon-triangle-up: "\EAAF";
$wt-icon-triangle: "\EAB0";
$wt-icon-twitter: "\EAB1";
$wt-icon-undo-o-1: "\EAB2";
$wt-icon-undo: "\EAB3";
$wt-icon-upload-cloud-o-1: "\EAB4";
$wt-icon-wanted: "\EAB5";
$wt-icon-wantedly-o-1: "\EAB6";
$wt-icon-wantedly-o-2: "\EAB7";
$wt-icon-wantedly: "\EAB8";
$wt-icon-whatsapp: "\EAB9";
$wt-icon-windows: "\EABA";


.wt-icon-android:before { content: $wt-icon-android; }
.wt-icon-apple:before { content: $wt-icon-apple; }
.wt-icon-arrow-down:before { content: $wt-icon-arrow-down; }
.wt-icon-at-mark-o-1:before { content: $wt-icon-at-mark-o-1; }
.wt-icon-at-mark:before { content: $wt-icon-at-mark; }
.wt-icon-b:before { content: $wt-icon-b; }
.wt-icon-balloon-o-1:before { content: $wt-icon-balloon-o-1; }
.wt-icon-balloon-o-2:before { content: $wt-icon-balloon-o-2; }
.wt-icon-balloon:before { content: $wt-icon-balloon; }
.wt-icon-balloons-o-1:before { content: $wt-icon-balloons-o-1; }
.wt-icon-balloons-o-2:before { content: $wt-icon-balloons-o-2; }
.wt-icon-balloons:before { content: $wt-icon-balloons; }
.wt-icon-bell-o-1:before { content: $wt-icon-bell-o-1; }
.wt-icon-bell-o-2:before { content: $wt-icon-bell-o-2; }
.wt-icon-bell:before { content: $wt-icon-bell; }
.wt-icon-bers-o-1:before { content: $wt-icon-bers-o-1; }
.wt-icon-bers:before { content: $wt-icon-bers; }
.wt-icon-bookmark-o-1:before { content: $wt-icon-bookmark-o-1; }
.wt-icon-bookmark-o-2:before { content: $wt-icon-bookmark-o-2; }
.wt-icon-bookmark:before { content: $wt-icon-bookmark; }
.wt-icon-business-bag-o-1:before { content: $wt-icon-business-bag-o-1; }
.wt-icon-business-bag-o-2:before { content: $wt-icon-business-bag-o-2; }
.wt-icon-business-bag:before { content: $wt-icon-business-bag; }
.wt-icon-calendar-o-1:before { content: $wt-icon-calendar-o-1; }
.wt-icon-calendar:before { content: $wt-icon-calendar; }
.wt-icon-camera-o-2:before { content: $wt-icon-camera-o-2; }
.wt-icon-camera:before { content: $wt-icon-camera; }
.wt-icon-chavron-down-o-1:before { content: $wt-icon-chavron-down-o-1; }
.wt-icon-chavron-down:before { content: $wt-icon-chavron-down; }
.wt-icon-chavron-up:before { content: $wt-icon-chavron-up; }
.wt-icon-check-circle:before { content: $wt-icon-check-circle; }
.wt-icon-check:before { content: $wt-icon-check; }
.wt-icon-chevron-left-o-1:before { content: $wt-icon-chevron-left-o-1; }
.wt-icon-chevron-left:before { content: $wt-icon-chevron-left; }
.wt-icon-chevron-right-o-1:before { content: $wt-icon-chevron-right-o-1; }
.wt-icon-chevron-right:before { content: $wt-icon-chevron-right; }
.wt-icon-chevron-up-o-1:before { content: $wt-icon-chevron-up-o-1; }
.wt-icon-circle:before { content: $wt-icon-circle; }
.wt-icon-circles-o-1:before { content: $wt-icon-circles-o-1; }
.wt-icon-circles:before { content: $wt-icon-circles; }
.wt-icon-clock-o-2:before { content: $wt-icon-clock-o-2; }
.wt-icon-clock:before { content: $wt-icon-clock; }
.wt-icon-close-circle-o-1:before { content: $wt-icon-close-circle-o-1; }
.wt-icon-close-circle-o-2:before { content: $wt-icon-close-circle-o-2; }
.wt-icon-close-circle:before { content: $wt-icon-close-circle; }
.wt-icon-close-o-1:before { content: $wt-icon-close-o-1; }
.wt-icon-close:before { content: $wt-icon-close; }
.wt-icon-code:before { content: $wt-icon-code; }
.wt-icon-company-o-1:before { content: $wt-icon-company-o-1; }
.wt-icon-company-o-2:before { content: $wt-icon-company-o-2; }
.wt-icon-company-plus-o-1:before { content: $wt-icon-company-plus-o-1; }
.wt-icon-company-plus-o-2:before { content: $wt-icon-company-plus-o-2; }
.wt-icon-company-plus:before { content: $wt-icon-company-plus; }
.wt-icon-company:before { content: $wt-icon-company; }
.wt-icon-compass-o-1:before { content: $wt-icon-compass-o-1; }
.wt-icon-compass-o-2:before { content: $wt-icon-compass-o-2; }
.wt-icon-compass:before { content: $wt-icon-compass; }
.wt-icon-copy-o-1:before { content: $wt-icon-copy-o-1; }
.wt-icon-copy:before { content: $wt-icon-copy; }
.wt-icon-crip-o-1:before { content: $wt-icon-crip-o-1; }
.wt-icon-crip:before { content: $wt-icon-crip; }
.wt-icon-dashboard:before { content: $wt-icon-dashboard; }
.wt-icon-door-o-1:before { content: $wt-icon-door-o-1; }
.wt-icon-door-o-2:before { content: $wt-icon-door-o-2; }
.wt-icon-door:before { content: $wt-icon-door; }
.wt-icon-download-circle-o-2:before { content: $wt-icon-download-circle-o-2; }
.wt-icon-download-circle:before { content: $wt-icon-download-circle; }
.wt-icon-download-cloud-o-2:before { content: $wt-icon-download-cloud-o-2; }
.wt-icon-download-cloud:before { content: $wt-icon-download-cloud; }
.wt-icon-download:before { content: $wt-icon-download; }
.wt-icon-ellipsis-h-o-1:before { content: $wt-icon-ellipsis-h-o-1; }
.wt-icon-ellipsis-h:before { content: $wt-icon-ellipsis-h; }
.wt-icon-ellipsis-v-o-1:before { content: $wt-icon-ellipsis-v-o-1; }
.wt-icon-ellipsis-v:before { content: $wt-icon-ellipsis-v; }
.wt-icon-exclamation-circle-o-1:before { content: $wt-icon-exclamation-circle-o-1; }
.wt-icon-exclamation-circle-o-2:before { content: $wt-icon-exclamation-circle-o-2; }
.wt-icon-exclamation-circle:before { content: $wt-icon-exclamation-circle; }
.wt-icon-eyes:before { content: $wt-icon-eyes; }
.wt-icon-facebook:before { content: $wt-icon-facebook; }
.wt-icon-file-o-1:before { content: $wt-icon-file-o-1; }
.wt-icon-file-o-2:before { content: $wt-icon-file-o-2; }
.wt-icon-file:before { content: $wt-icon-file; }
.wt-icon-fire-o-1:before { content: $wt-icon-fire-o-1; }
.wt-icon-fire:before { content: $wt-icon-fire; }
.wt-icon-folder-o-1:before { content: $wt-icon-folder-o-1; }
.wt-icon-folder-o-2:before { content: $wt-icon-folder-o-2; }
.wt-icon-folder:before { content: $wt-icon-folder; }
.wt-icon-gear-o-1:before { content: $wt-icon-gear-o-1; }
.wt-icon-gear:before { content: $wt-icon-gear; }
.wt-icon-github:before { content: $wt-icon-github; }
.wt-icon-google:before { content: $wt-icon-google; }
.wt-icon-h2:before { content: $wt-icon-h2; }
.wt-icon-h3:before { content: $wt-icon-h3; }
.wt-icon-hash:before { content: $wt-icon-hash; }
.wt-icon-hatena:before { content: $wt-icon-hatena; }
.wt-icon-heart-o-2:before { content: $wt-icon-heart-o-2; }
.wt-icon-heart:before { content: $wt-icon-heart; }
.wt-icon-infomation-o-1:before { content: $wt-icon-infomation-o-1; }
.wt-icon-infomation-o-2:before { content: $wt-icon-infomation-o-2; }
.wt-icon-infomation:before { content: $wt-icon-infomation; }
.wt-icon-jump:before { content: $wt-icon-jump; }
.wt-icon-label-text-o-1:before { content: $wt-icon-label-text-o-1; }
.wt-icon-label-text-o-2:before { content: $wt-icon-label-text-o-2; }
.wt-icon-label-text:before { content: $wt-icon-label-text; }
.wt-icon-line-chart-o-2:before { content: $wt-icon-line-chart-o-2; }
.wt-icon-link:before { content: $wt-icon-link; }
.wt-icon-linkedin:before { content: $wt-icon-linkedin; }
.wt-icon-list:before { content: $wt-icon-list; }
.wt-icon-location:before { content: $wt-icon-location; }
.wt-icon-mail-o-1:before { content: $wt-icon-mail-o-1; }
.wt-icon-mail-o-2:before { content: $wt-icon-mail-o-2; }
.wt-icon-mail:before { content: $wt-icon-mail; }
.wt-icon-message-o-1:before { content: $wt-icon-message-o-1; }
.wt-icon-message-o-2:before { content: $wt-icon-message-o-2; }
.wt-icon-message:before { content: $wt-icon-message; }
.wt-icon-minus-o-1:before { content: $wt-icon-minus-o-1; }
.wt-icon-minus:before { content: $wt-icon-minus; }
.wt-icon-newspaper-o-1:before { content: $wt-icon-newspaper-o-1; }
.wt-icon-newspaper-o-2:before { content: $wt-icon-newspaper-o-2; }
.wt-icon-newspaper:before { content: $wt-icon-newspaper; }
.wt-icon-pencil-o-1:before { content: $wt-icon-pencil-o-1; }
.wt-icon-pencil-paper-o-1:before { content: $wt-icon-pencil-paper-o-1; }
.wt-icon-pencil-paper:before { content: $wt-icon-pencil-paper; }
.wt-icon-pencil:before { content: $wt-icon-pencil; }
.wt-icon-person-o-1:before { content: $wt-icon-person-o-1; }
.wt-icon-person-o-2:before { content: $wt-icon-person-o-2; }
.wt-icon-person-plus-o-1:before { content: $wt-icon-person-plus-o-1; }
.wt-icon-person-plus-o-2:before { content: $wt-icon-person-plus-o-2; }
.wt-icon-person-plus:before { content: $wt-icon-person-plus; }
.wt-icon-person:before { content: $wt-icon-person; }
.wt-icon-phone-o-1:before { content: $wt-icon-phone-o-1; }
.wt-icon-phone:before { content: $wt-icon-phone; }
.wt-icon-pin:before { content: $wt-icon-pin; }
.wt-icon-plus-circle-o-1:before { content: $wt-icon-plus-circle-o-1; }
.wt-icon-plus-o-1:before { content: $wt-icon-plus-o-1; }
.wt-icon-plus:before { content: $wt-icon-plus; }
.wt-icon-quote:before { content: $wt-icon-quote; }
.wt-icon-refresh-o-1:before { content: $wt-icon-refresh-o-1; }
.wt-icon-refresh:before { content: $wt-icon-refresh; }
.wt-icon-reply:before { content: $wt-icon-reply; }
.wt-icon-right-pane-o-1:before { content: $wt-icon-right-pane-o-1; }
.wt-icon-right-pane:before { content: $wt-icon-right-pane; }
.wt-icon-search-o-1:before { content: $wt-icon-search-o-1; }
.wt-icon-search-short-o-1:before { content: $wt-icon-search-short-o-1; }
.wt-icon-search-short:before { content: $wt-icon-search-short; }
.wt-icon-search:before { content: $wt-icon-search; }
.wt-icon-separator:before { content: $wt-icon-separator; }
.wt-icon-share-apple:before { content: $wt-icon-share-apple; }
.wt-icon-share-google:before { content: $wt-icon-share-google; }
.wt-icon-share-ms:before { content: $wt-icon-share-ms; }
.wt-icon-share:before { content: $wt-icon-share; }
.wt-icon-sliders:before { content: $wt-icon-sliders; }
.wt-icon-spanner-o-1:before { content: $wt-icon-spanner-o-1; }
.wt-icon-spanner:before { content: $wt-icon-spanner; }
.wt-icon-star-o-1:before { content: $wt-icon-star-o-1; }
.wt-icon-star:before { content: $wt-icon-star; }
.wt-icon-support:before { content: $wt-icon-support; }
.wt-icon-tag-o-1:before { content: $wt-icon-tag-o-1; }
.wt-icon-tag-o-2:before { content: $wt-icon-tag-o-2; }
.wt-icon-tag:before { content: $wt-icon-tag; }
.wt-icon-target:before { content: $wt-icon-target; }
.wt-icon-text-square-o-1:before { content: $wt-icon-text-square-o-1; }
.wt-icon-text-square-o-2:before { content: $wt-icon-text-square-o-2; }
.wt-icon-text-square:before { content: $wt-icon-text-square; }
.wt-icon-thumb-up-o-1:before { content: $wt-icon-thumb-up-o-1; }
.wt-icon-thumb-up-o-2:before { content: $wt-icon-thumb-up-o-2; }
.wt-icon-thumb-up:before { content: $wt-icon-thumb-up; }
.wt-icon-trash-o-1:before { content: $wt-icon-trash-o-1; }
.wt-icon-trash-o-2:before { content: $wt-icon-trash-o-2; }
.wt-icon-trash:before { content: $wt-icon-trash; }
.wt-icon-trencher-o-1:before { content: $wt-icon-trencher-o-1; }
.wt-icon-trencher-o-2:before { content: $wt-icon-trencher-o-2; }
.wt-icon-trencher:before { content: $wt-icon-trencher; }
.wt-icon-triangle-down:before { content: $wt-icon-triangle-down; }
.wt-icon-triangle-up:before { content: $wt-icon-triangle-up; }
.wt-icon-triangle:before { content: $wt-icon-triangle; }
.wt-icon-twitter:before { content: $wt-icon-twitter; }
.wt-icon-undo-o-1:before { content: $wt-icon-undo-o-1; }
.wt-icon-undo:before { content: $wt-icon-undo; }
.wt-icon-upload-cloud-o-1:before { content: $wt-icon-upload-cloud-o-1; }
.wt-icon-wanted:before { content: $wt-icon-wanted; }
.wt-icon-wantedly-o-1:before { content: $wt-icon-wantedly-o-1; }
.wt-icon-wantedly-o-2:before { content: $wt-icon-wantedly-o-2; }
.wt-icon-wantedly:before { content: $wt-icon-wantedly; }
.wt-icon-whatsapp:before { content: $wt-icon-whatsapp; }
.wt-icon-windows:before { content: $wt-icon-windows; }
