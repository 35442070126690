@import "./mixins.scss";

.careers {
  .lead {
    padding-bottom: 80px;
  }

  .image-shadow {
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  }

  .campaigns {
    .banner-image {
      transition: all 300ms;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.1);
      }
    }
    @include pc {
      display: flex;
      margin: 140px auto 120px;
      justify-content: center;
      a {
        margin-left: 30px;
        &:first-child {
          margin-left: 0px;
        }
      }
      img {
        width: 300px;
        height: 300px;
      }
      @media screen and (max-width: 1090px) {
        img {
          width: 20vw;
          height: 20vw;
        }
      }
    }
    @include mobile-tablet {
      margin: 120px auto 100px;
      width: 85%;
      max-width: 400px;
      a {
        display: block;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    @include tablet {
      width: 80%;
    }
  }
}

#careers-values {
  overflow-x: hidden;
  .image-block {
    position: relative;
  }
  .bar {
    position: absolute;
  }
  .catch {
    margin: 0 auto 30px;
    font-weight: 600;
    letter-spacing: 2.6px;
    color: rgba(0, 0, 0, 0.7);
  }
  .statement {
    font-weight: 500;
    line-height: 1.7;
    p {
      margin-bottom: 1.5em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .values {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
  }
  @include pc {
    .main-content {
      width: 700px;
    }
    .image-block {
      margin-bottom: 60px;
    }
    .bar {
      width: 209px;
      top: -57px;
      left: -107px;
    }
    .text-block {
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .catch {
      font-size: 26px;
    }
    .values {
      margin-bottom: 80px;
    }
    .statement {
      width: 700px;
      font-size: 14px;
      letter-spacing: 1.4px;
      color: rgba(0, 0, 0, 0.5);
    }

    .left-image-wrapper {
      margin-left: calc((100vw - 700px) / 2 * -1);
      width: 100vw;
      display: flex;
      justify-content: center;
      margin-bottom: 60px;
    }
    .left-image {
      margin: 20px 0;
    }
    .values {
      font-size: 16px;
      line-height: 1.75;
      letter-spacing: 1.6px;
      p {
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .head-image {
      width: 980px;
    }
    .left-image {
      width: 1080px;
      img {
        width: 100%;
      }
    }
    @media screen and (max-width: 1280px) {
      .main-content {
        width: 55%;
      }
      .head-image,
      .left-image {
        width: calc(100vw - (100vw - 55vw) / 2);
      }
      .bar {
        width: auto;
        height: 130%;
        left: -10%;
      }
      .left-image-wrapper {
        margin-left: -22.5vw;
      }
      .statement {
        width: auto;
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      width: 100%;
    }
    .image-block-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 80px;
    }
    .image-block {
      width: 85%;
    }
    .head-image {
      width: 100%;
    }
    .bar {
      width: 35%;
      top: 8%;
      left: -10%;
    }
    .catch {
      font-size: 20px;
      line-height: 1.2;
      text-align: center;
    }
    .text-block {
      max-width: 600px;
      width: 85%;
      margin: 0 auto 60px;
    }
    .statement {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.6);
    }
    .left-image-wrapper {
      width: 100%;
    }
    .left-image {
      width: 80%;
      margin: 0 auto 60px;
      img {
        width: 100%;
      }
    }
    .values {
      font-size: 14px;
      line-height: 1.86;
      letter-spacing: 1.4px;
      p {
        line-height: 1.69;
        letter-spacing: 1px;
      }
    }
  }
  @include tablet {
    .text-block {
      max-width: 600px;
      width: 80%;
    }
  }
}

#careers-values-user-obsession {
  img {
    @include pc {
      height: 26px;
    }
    @include mobile-tablet {
      height: 20px;
    }
  }
  h3 {
    @include pc {
      margin: 0 0 32px 0;
    }
    @include mobile-tablet {
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
}

#careers-values-code-wins-arguments {
  img {
    @include pc {
      height: 30px;
    }
    @include mobile-tablet {
      height: 22px;
    }
  }
  h3 {
    @include pc {
      margin: 0 0 32px 0;
    }
    @include mobile-tablet {
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
}

#careers-values-less-is-more {
  img {
    @include pc {
      height: 26px;
    }
    @include mobile-tablet {
      height: 20px;
    }
  }
  h3 {
    @include pc {
      margin: 0 0 32px 0;
    }
    @include mobile-tablet {
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
}

#careers-values-move-fast {
  img {
    @include pc {
      height: 26px;
    }
    @include mobile-tablet {
      height: 20px;
    }
  }
  h3 {
    @include pc {
      margin: 0 0 32px 0;
    }
    @include mobile-tablet {
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
}

#careers-values-get-things-done {
  img {
    @include pc {
      height: 30px;
    }
    @include mobile-tablet {
      height: 22px;
    }
  }
  h3 {
    @include pc {
      margin: 0 0 32px 0;
    }
    @include mobile-tablet {
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
}

#careers-values-one-team {
  img {
    @include pc {
      height: 26px;
    }
    @include mobile-tablet {
      height: 20px;
    }
  }
  h3 {
    @include pc {
      margin: 0 0 32px 0;
    }
    @include mobile-tablet {
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
}
#careers-jobs {
  .copy {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
  }
  .attract {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.5);
  }
  .image-block {
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 480ms ease-in-out;
      width: 100%;
      &.show {
        opacity: 1;
      }
    }
  }
  @include pc {
    .main-content {
      max-width: 960px;
      width: 75%;
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 97px;
      align-items: center;
    }
    .image-block {
      margin-right: 30px;
      width: 70%;
      height: 400px;
      img {
        width: 100%;
        max-width: 630px;
      }
    }
    .text-block {
      margin-top: 16px;
      width: 30%;
    }
    .copy {
      font-size: 24px;
      line-height: 1.46;
      letter-spacing: 2.6px;
      margin-bottom: 30px;
    }
    .attract {
      margin-bottom: 36px;
      line-height: 1.85;
    }
  }
  @include mobile-tablet {
    .main-content {
      width: 100%;
    }
    .image-block {
      height: 67vw;
      margin-bottom: 72px;
    }
    .copy {
      text-align: center;
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 24px;
    }
    .attract {
      margin: 0 auto;
      width: 86%;
      margin-bottom: 48px;
      color: rgba(0, 0, 0, 0.6);
      line-height: 1.69;
    }
    .btn {
      margin: 0 auto;
      width: 260px;
      margin-bottom: 64px;
    }
  }
}

#careers-benefits {
  .main-content {
    h3 {
      text-align: center;
      color: rgba(0, 0, 0, 0.84);
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 1%;
      font-weight: 600;
    }
  }
}
#careers-culture {
  .main-content {
    h3 {
      text-align: center;
      color: rgba(0, 0, 0, 0.7);
      font-size: 20px;
      line-height: 0.8;
      letter-spacing: 2px;
      font-weight: 600;
      @include pc {
        margin: 0 auto 80px;
      }
      @include mobile-tablet {
        margin: 0 auto 60px;
      }
    }
    h4 {
      letter-spacing: 1.6px;
      color: rgba(0, 0, 0, 0.7);
      font-weight: 600;
      font-size: 16px;
      @include pc {
        line-height: 1;
      }
      @include mobile-tablet {
        line-height: 1.5;
      }
    }
    p {
      @include pc {
        line-height: 1.57;
        letter-spacing: 1.4px;
        color: rgba(0, 0, 0, 0.5);
      }
      @include mobile-tablet {
        line-height: 1.69;
        letter-spacing: 1px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

#careers-culture {
  .training-items {
    margin: 0 auto;
  }
  .training-item {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .culture-items,
  .exchange-items {
    margin-bottom: 120px;
  }
  @include pc {
    .main-content {
      width: 940px;
    }
    .culture-items {
      margin-bottom: 120px;
    }
    .culture-items,
    .exchange-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .culture-item,
    .exchange-item {
      width: 430px;
    }
    .culture-item {
      margin-bottom: 80px;
      h4 {
        line-height: 1.5;
        margin: 0 auto 20px;
      }
      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
      img {
        margin-bottom: 40px;
        width: 100%;
      }
    }
    .exchange-item {
      h4 {
        margin: 0 auto 26px;
      }
    }
    .training-items {
      width: 620px;
    }
    .training-item {
      margin-bottom: 60px;
      h4 {
        margin: 0 auto 24px;
      }
    }
    @media screen and (max-width: 1220px) {
      .main-content {
        width: 750px;
      }
      .culture-item,
      .exchange-item {
        width: 350px;
      }
    }
    @media screen and (max-width: 1010px) {
      .main-content {
        width: 450px;
      }
      .culture-items,
      .exchange-items {
        margin: 0 auto 120px;
        flex-direction: column;
      }
      .training-items {
        width: auto;
      }
      .culture-item,
      .exchange-item,
      .training-item {
        width: 100%;
      }
      .culture-item,
      .exchange-item {
        &:nth-last-child(2) {
          margin-bottom: 80px;
        }
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      width: 100%;
    }
    .culture-item {
      &:nth-child(odd) {
        .culture-img-wrapper {
          margin: 0 0 30px auto;
        }
      }
      &:nth-child(even) {
        .culture-img-wrapper {
          margin: 0 auto 30px 0;
        }
      }
    }
    .culture-img-wrapper {
      width: 85%;
      img {
        width: 100%;
      }
      margin-bottom: 30px;
    }
    .text-block {
      margin: 0 auto;
      width: 85%;
    }
    .culture-item h4,
    .exchange-item h4,
    .training-item h4 {
      margin-bottom: 18px;
    }
    .culture-item,
    .exchange-item,
    .training-item {
      margin-bottom: 60px;
    }
  }
  @include tablet {
    .text-block {
      width: 80%;
    }
    .culture-img-wrapper {
      width: 80%;
    }
  }
}

#careers-benefits {
  .list-image {
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .desc {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
  }
  .image-shadow {
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.08);
  }
  .images-wrapper {
    overflow: hidden;
    position: relative;
    width: 100vw;
    img {
      position: absolute;
      opacity: 0.6;
      &.show {
        opacity: 1;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
  .images {
    padding: 10px 0;
    margin: auto;
  }
  .page-control {
    display: flex;
    width: 280px;
    margin: 20px auto 0;
  }
  .page-index {
    box-sizing: border-box;
    width: 48px;
    height: 10px;
    margin-right: 10px;
    &:before {
      margin-top: 4px;
      content: "";
      position: absolute;
      width: 48px;
      height: 2px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    &.show:before {
      background-color: rgba(0, 0, 0, 0.4);
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  .benefits-content {
    display: flex;
    flex-direction: column;
    gap: 80px;
    h3 {
      margin: 0;
    }
  }
  @include pc {
    .main-content {
      display: flex;
      flex-direction: column;
      width: 980px;
    }
    .benefits-title {
      margin: 0;
    }
    .benefits-container {
      display: flex;
      flex-direction: column;
      gap: 80px;
      width: 824px;
      margin: 0 auto;
    }
    .list-items {
      display: flex;
      flex-wrap: wrap;
      row-gap: 48px;
      column-gap: 24px;
    }
    .item {
      display: flex;
      flex-direction: column;
      width: 400px;
      box-sizing: border-box;
      gap: 16px;
      h4 {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.84);
      }
      p {
        font-weight: 300;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.84);
      }
    }
    .list-text {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .list-image {
      height: 30px;
      width: 30px;
    }
    .images-wrapper {
      left: calc((100vw - 980px) / 2 * -1);
    }
    .images {
      width: 980px;
      height: 460px;
    }
    .head-image {
      width: 980px;
      height: 460px;
    }
    .desc {
      text-align: center;
      margin-bottom: 60px;
      line-height: 1;
      letter-spacing: 1.6px;
      margin-top: 80px;
    }
    .page-index {
      cursor: pointer;
    }
    .office-title {
      margin: 120px 0 0 0;
    }
    @media screen and (max-width: 1280px) {
      .main-content {
        width: 75%;
      }
      .benefits-container {
        width: 100%;
      }
      .list-items {
        justify-content: center;
      }
      .head-image {
        width: 100%;
      }
      .images-wrapper {
        left: calc((100vw - 75vw) / 2 * -1);
      }
      .images {
        width: 75%;
        height: calc(75vw * 460 / 980);
        img {
          width: 75%;
        }
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      display: flex;
      flex-direction: column;
      gap: 129px;
    }
    .benefits-container {
      display: flex;
      flex-direction: column;
      gap: 129px;
    }
    .item {
      text-align: center;
      margin-bottom: 40px;
      h4 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.84);
        margin: 8px 0 0 0;
      }
      p {
        font-weight: 300;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.84);
        text-align: left;
        margin: 16px 0 0 0;
      }
    }
    .list-items {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .list-image {
      height: 40px;
      width: 40px;
      margin: 0 auto;
      background-size: contain;
    }
    .head-image {
      width: 80vw;
    }
    .desc {
      line-height: 1.86;
      letter-spacing: 1.4px;
      margin: 40px 0;
    }
    .images-wrapper {
      left: calc(15vw / 2 * -1);
    }
    .images {
      width: 85vw;
      height: calc(85vw * 240 / 355);
      img {
        width: 85vw;
      }
    }
    .office-title {
      margin: 0;
    }
  }
  @include tablet {
    .images-wrapper {
      left: calc(20vw / 2 * -1);
    }
    .images {
      width: 80vw;
      height: calc(80vw * 240 / 355);
      img {
        width: 80vw;
      }
    }
  }
}

#careers-people {
  .item {
    line-height: 0;
    h4 {
      margin: 0;
    }
    .image-shadow {
      box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.08);
    }
  }
  .position {
    margin: 20px auto 10px;
    font-weight: 300;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 1;
  }
  .title {
    font-weight: 600;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.7);
  }
  @include pc {
    .main-content {
      width: 980px;
    }
    .items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: -80px;
    }
    .item {
      margin-bottom: 80px;
      width: 300px;
      @include link;
      &:nth-child(n + 7) {
        margin-bottom: 0;
      }
      img {
        width: 300px;
      }
    }
    .title {
      line-height: 1.62;
    }
    @media screen and (max-width: 1280px) {
      .main-content {
        width: 660px;
      }
      .head-image {
        width: 100%;
      }
    }
    @media screen and (max-width: 972px) {
      .main-content {
        width: 400px;
      }
      .item {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      width: 80%;
    }
    .item {
      display: block;
      margin-bottom: 60px;
      img {
        width: 100%;
      }
    }
    .title {
      line-height: 1.5;
      font-size: 16px;
    }
  }
  @include tablet {
    .main-content {
      width: 70%;
    }
  }
}
#careers-presentations {
  .desc {
    font-weight: 600;
    font-size: 15px;
    line-height: 1.87;
    letter-spacing: 1.1px;
    color: #45494b;
  }
  .presentations-wrapper {
    display: flex;
  }
  .thumb-wrapper {
    display: flex;
    justify-content: center;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
    position: relative;
    overflow: hidden;
    background-color: #000;
  }
  .thumb-back {
    width: 105%;
    filter: blur(3px);
    opacity: 0.94;
    position: absolute;
    top: -5px;
    left: -5px;
  }
  .thumb {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .left-block {
    display: flex;
  }
  .avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
  .published {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.4);
  }
  .name {
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.57;
    color: rgba(0, 0, 0, 0.84);
  }
  .tags {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .tag {
    border-radius: 12px;
    background-color: #424242;
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    padding: 0 16px;
    height: 23px;
    margin-bottom: 4px;
    line-height: 23px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .pagination-wrapper {
    display: flex;
    justify-content: center;
  }
  @include pc {
    .main-content {
      width: 977px;
      max-width: 1020px;
    }
    .select-box-container {
      margin-left: 17px;
    }
    .desc {
      text-align: center;
      margin-bottom: 60px;
    }
    .presentations-wrapper {
      margin-bottom: 20px;
      flex-wrap: wrap;
    }
    .presentation-item {
      margin: 0 17px;
      width: calc(164px * 16 / 9);
    }
    .thumb-wrapper {
      height: 164px;
    }
    .thumb {
      height: 164px;
    }
    .info-block {
      margin: 14px 0 48px;
      display: flex;
      justify-content: space-between;
    }
    .name {
      font-weight: 700;
    }
    @media screen and (max-width: 977px) {
      .main-content {
        width: 652px;
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      width: 86%;
    }
    .desc {
      margin-bottom: 40px;
    }
    .presentations-wrapper {
      margin: 40px auto 60px;
      flex-direction: column;
      align-items: center;
    }
    .presentation-item {
      width: 100%;
      max-width: 304px;
    }
    .thumb-wrapper {
      max-width: 324px;
      width: 86vw;
      height: calc(86vw * 9 / 16);
      max-height: 182px;
    }
    .thumb {
      height: 100%;
    }
    .info-block {
      margin: 18px 0 34px;
      display: flex;
      justify-content: space-between;
    }
    .name {
      font-weight: 600;
    }
  }
}
#admin-presentations-index {
  th,
  td {
    &:nth-child(2) {
      width: 600px;
    }
  }
}
#admin-presentations-new {
  .input-wrapper {
    display: flex;
    flex-direction: column;
    width: 400px;
    p {
      line-height: 1.6;
      font-size: 14px;
      margin-top: 4px;
    }
  }
}
#admin-presentations-check-registration {
  .main-content {
    width: 50%;
    margin: 0 auto;
  }
  .form-item {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 25px;
    label {
      margin-right: 8px;
      width: 120px;
    }
    textarea {
      width: 500px;
      height: 400px;
      font-size: 14px;
    }
  }
  .btn {
    margin: 0 auto 120px;
  }
  .link {
    color: #00a3bc;
  }
  .form {
    margin-top: 20px;
  }
}
#admin-presentations-check-registration-result {
  .main-content {
    width: 100%;
    margin: 0 auto 120px;
  }
  .item {
    margin-bottom: 16px;
  }
  .register {
    border: solid 1px #888;
    padding: 4px;
    margin-right: 4px;
  }
}
ul#ui-id-1 {
  background-color: #fff;
}
