@import "./mixins.scss";

.company {
  &.main-inner {
    @include pc {
      max-width: 1440px;
      margin-bottom: 140px;
    }
    @include mobile-tablet {
      margin-bottom: 120px;
    }
  }
}

.company-footer {
  @include pc {
    width: 660px;
    margin: 120px auto 0;
    display: flex;
    justify-content: space-between;
    .company-footer-item {
      display: flex;
      flex-direction: column;
    }
    .box {
      width: 120px;
      height: 120px;
      border-radius: 10px;
      border: solid 1px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
    }
    .caption {
      margin: 20px auto 0;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      letter-spacing: 2px;
      color: #9da0a4;
    }
  }
  @include mobile-tablet {
    margin: 120px auto 0;
    width: 280px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .company-footer-item {
      display: flex;
      flex-direction: column;
    }
    .box {
      width: 120px;
      height: 120px;
      border-radius: 10px;
      border: solid 1px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
    }
    .caption {
      margin: 20px auto 54px;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      letter-spacing: 1.8px;
      color: #9da0a4;
    }
  }
}

#company-mission {
  @include pc {
    .main-content {
      width: 1060px;
      margin-left: 220px;
      display: flex;
    }
    .text-block {
      min-width: 300px;
    }
    .catch {
      margin: 60px 0 40px;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.84);
    }
    .statement {
      color: rgba(0, 0, 0, 0.84);
    }
    .image-block {
      width: 500px;
      height: 530px;
      margin-left: 100px;
      position: relative;
    }
    .mission-image {
      width: 500px;
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
    }
    .bar {
      position: absolute;
      top: 27px;
      left: -72px;
    }
    @media screen and (max-width: 1280px) {
      .main-content {
        width: 60%;
        margin: 0 auto;
        flex-direction: column;
      }
      .text-block {
        margin: 0 auto;
        order: 1;
      }
      .image-block {
        margin: 0 auto 60px;
        order: 0;
        width: 80%;
        height: auto;
      }
      .bar {
        top: 27px;
        left: -15%;
        width: 34%;
      }
      .mission-image {
        width: 100%;
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      max-width: 600px;
      margin-bottom: 120px;
      display: flex;
      flex-direction: column-reverse;
    }
    .catch {
      margin: 0;
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: normal;
      text-align: center;
      color: rgba(0, 0, 0, 0.84);
    }
    .statement {
      margin-top: 40px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.8;
      letter-spacing: 1px;
      color: rgba(0, 0, 0, 0.84);
    }
    .image-block {
      text-align: center;
      width: 100%;
      position: relative;
      margin-bottom: 80px;
    }
    .mission-image {
      top: 0px;
      width: 100%;
      box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.1);
    }
    .bar {
      position: absolute;
      top: 12%;
      left: -8%;
      width: 35%;
    }
  }
}

#company-info {
  @include pc {
    .list-head,
    .list-data {
      line-height: 2.0;
    }
  }
}

#company-leadership {
  @include pc {
    .main-content {
      width: 1060px;
      padding-left: 220px;
      display: flex;
    }
    .member-nav {
      margin-bottom: 100px;
      display: flex;
      justify-content: center;
    }
    .member-nav-window {
      max-width: 1030px;
      margin: 0 20px;
      overflow: hidden;
      flex-shrink: 0;
    }
    .member-nav-items-container {
      display: flex;
      transition: transform 250ms;
    }
    .member-nav.narrow {
      .member-nav-window {
        width: 640px;
      }
      .member-nav-items-container {
        transition: transform 400ms;
      }
    }
    .nav-left,
    .nav-right {
      width: 44px;
      height: 44px;
      margin-top: 30px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.7);
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
      text-align: center;
      flex-shrink: 0;
      cursor: pointer;
      i {
        font-size: 26px;
        line-height: 44px;
        color: white;
      }
    }
    .member-nav-item {
      margin-right: 30px;
      padding: 1px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover .avatar-wrapper {
        .avatar1 {
          display: none;
        }
        .avatar2 {
          display: block;
        }
      }
    }
    .avatar-wrapper {
      width: 100px;
      height: 100px;
      padding: 10px;
      box-sizing: border-box;
      &.selected {
        position: relative;
        background: white;
        border-radius: 50%;
        &:after {
          content: '';
          position: absolute;
          top: -1px;
          bottom: -1px;
          left: -1px;
          right: -1px;
          z-index: -1;
          background: linear-gradient(130deg, #1e7dde, #00c4c4);
          border-radius: 50%;
        }
        .avatar1 {
          display: none;
        }
        .avatar2 {
          display: block;
        }
      }
      .avatar1 {
        display: block;
      }
      .avatar2 {
        display: none;
      }
    }
    .avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    }
    .directors {
      background-image: linear-gradient(130deg, #1e7dde, #00c4c4);
    }
    .nav-name {
      width: 100px;
      height: 44px;
      margin-top: 20px;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.57;
      letter-spacing: 2px;
      text-align: center;
      color: #24282a;
    }
    .main-item-container {
      width: 1060px;
      min-height: 530px;
    }
    .main-item {
      align-items: center;
      display: none;
      &.show {
        display: block;
      }
    }
    .main-item-inner {
      display: flex;
    }
    .director-item {
      width: 440px;
      margin: 0 auto 80px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .text-block {
      min-width: 300px;
      padding-top: 155px;
    }
    .head {
      margin-bottom: 30px;
      font-size: 0;
    }
    .name,
    .title {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.7);
    }
    .name {
      font-weight: 600;
    }
    .image-block {
      width: 560px;
      height: 530px;
      margin-left: 60px;
      position: relative;
    }
    .bar {
      position: absolute;
      top: 27px;
      left: -72px;
      width: 209px;
    }
    .sidebar {
      top: 500px;
    }
    @media screen and (max-width: 1280px) {
      .main-content {
        width: 85%;
        min-height: 740px;
      }
      .main-item-inner {
        flex-direction: column;
      }
      .image-block-wrapper {
        order: 0;
        width: 100%;
      }
      .image-block {
        margin: 0 auto;
        width: 550px;
      }
      .member-image {
        width: 550px;
      }
      .text-block {
        order: 1;
        padding-top: 60px;
        width: 90%;
        margin: 0 auto;
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      width: 100%;
    }
    .member-nav-window {
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      height: 160px;
    }
    .member-nav-items-container {
      display: flex;
      margin-top: 2px;
      transition: transform 250ms;
    }
    .member-nav-item {
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      margin-right: 30px;
      &:first-child {
        margin-left: 40px;
      }
      &:last-child {
        padding-right: 40px;
      }
      &:hover .avatar-wrapper {
        .avatar1 {
          display: none;
        }
        .avatar2 {
          display: block;
        }
      }
    }
    .avatar-wrapper {
      width: 80px;
      height: 80px;
      position: relative;
      box-sizing: border-box;
      .avatar1 {
        display: block;
      }
      .avatar2 {
        display: none;
      }
      &.selected {
        .avatar1 {
          display: none;
        }
        .avatar2 {
          display: block;
        }
        border-radius: 50%;
        &:after {
          content: '';
          position: absolute;
          top: -2px;
          bottom: -2px;
          left: -2px;
          right: -2px;
          z-index: -1;
          background: linear-gradient(130deg, #1e7dde, #00c4c4);
          border-radius: 50%;
        }
      }
    }
    .avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    }
    .directors {
      background-image: linear-gradient(130deg, #1e7dde, #00c4c4);
    }
    .nav-name {
      margin-top: 32px;
      font-family: Poppins, sans-serif;
      font-size: 13px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: 1px;
      text-align: center;
      color: #24282a;
    }
    .nav-dots {
      margin-top: 40px;
      margin-bottom: 80px;
      display: flex;
      justify-content: center;
    }
    .nav-dot {
      margin: -20px 0;
      padding: 20px 10px;
    }
    .nav-dot-inner {
      width: 12px;
      height: 12px;
      border-radius: 50%;

      background-color: rgba(0, 0, 0, 0.2);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      &.selected {
        background-image: linear-gradient(130deg, #1e7dde, #00c4c4);
      }
    }
    .main-item-container {
      width: 100%;
      min-height: 530px;
    }
    .main-item {
      display: none;
      &.show {
        display: block;
      }
    }
    .main-item-inner {
      display: flex;
      flex-flow: column-reverse;
    }
    .director-item {
      margin-left: 25px;
      margin-right: 25px;
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .text-block {
      width: 80%;
      margin: 0 auto;
      max-width: 600px;
    }
    .name,
    .title {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.7);
    }
    .name {
      font-weight: 600;
    }
    .desc {
      margin-top: 20px;
      color: #6a6e71;
    }
    .image-block-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 105px;
    }
    .image-block {
      width: 85%;
      position: relative;
    }
    .member-image {
      width: 100%;
    }
    .bar {
      position: absolute;
      top: 59px;
      left: -40px;
      height: 100%;
    }
  }
}

@media screen and (max-width: 1280px) {
  #company-mission,
  #company-leadership {
    width: auto;
    .container {
      display: block;
    }
    .main-content {
      box-sizing: border-box;
    }
  }
}
