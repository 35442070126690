@import "./mixins";

.select-box-container {
  display: flex;
  margin-bottom: 34px;
  .select-box-wrapper {
    cursor: pointer;
    &:first-child {
      margin-right: 20px;
    }
  }
  .select-box-head {
    display: inline-block;
    margin-bottom: 7px;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    line-height: normal;
    color: rgba(0, 0, 0, 0.4);
  }
  .select-box {
    position: relative;
    width: 220px;
    font-size: 14px;
  }
  .top-box {
    height: 40px;
    border: solid 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 8px 20px 8px 28px;
    i {
      font-size: 15px;
    }
  }
  .pull-down-box {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 7px 0;
    background: white;
    border: solid 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    &.open {
      visibility: visible;
      opacity: 1;
    }
    a {
      text-decoration: none;
    }
  }
  .pull-down-item {
    box-sizing: border-box;
    height: 38px;
    padding: 8px 20px 8px 28px;
    &:hover {
      background: #eeeeee;
    }
  }

  @include mobile-tablet {
    .select-box-wrapper {
      flex-grow: 1;
    }
    .select-box {
      width: auto;
      font-size: 13px;
    }
    .top-box {
      padding: 6px 16px 6px 16px;
      word-break: keep-all;
    }
  }
}
