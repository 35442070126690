@import "normalize.css";
@import "./mixins.scss";

body {
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "palt";
  font-size: 14px;
  line-height: 26px;
  color: #45494b;
  background-color: #fff;
}

a {
  color: #45494b;
  text-decoration: none;

  &:hover {
    color: #45494b;
    text-decoration: none;
  }
}

ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.link {
  color: #00a3bc;
  &:hover {
    color: #00a3bc;
  }
}

.tooltip {
  position: relative;
  z-index: 10;
  &:after {
    content: attr(data-tooltip-text);
    position: absolute;
    top: 60px;
    left: 0;
    padding: 10px 12px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    line-height: normal;
    visibility: hidden;
    opacity: 0;
    transition: all 150ms;
  }
  &:hover {
    &:after {
      visibility: visible;
      opacity: 1;
    }
  }
  @include mobile-tablet {
    &:after {
      left: auto;
      right: 0;
      top: calc(100% + 20px);
      box-sizing: border-box;
      padding: 17px 12px;
      text-align: center;
      font-size: 16px;
      line-height: 1;
      background-color: rgba(0, 0, 0, 0.7);
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
    }
    &:hover {
      &:after {
        visibility: hidden;
        opacity: 0;
      }
    }
    &.copied {
      &:after {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.btn {
  display: block;
  width: 220px;
  height: 44px;
  line-height: 44px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: all 300ms;

  @mixin highlight {
    color: #ffffff;
    text-decoration: none;
    background-image: linear-gradient(100deg, #1e7dde, #00c4c4);
    box-shadow: 0 9px 10px 0 rgba(0, 0, 0, 0.1);
  }
  @include pc {
    &:hover {
      @include highlight;
      transform: translateY(-3px);
    }
  }
  @include mobile-tablet {
    @include highlight;
  }
}

.btn-admin {
  background: rgba(0, 0, 0, 0);
  font-weight: 400;
  cursor: pointer;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.6);
  @include pc {
    border: solid 1px rgba(0, 0, 0, 0.6);
    width: 120px;
  }
}

.back-to-top-btn-wrapper {
  display: flex;
  justify-content: center;
  margin: 110px 0;
}

.signin {
  width: 100%;
  box-sizing: border-box;
  padding: 240px 0;
  display: flex;
  justify-content: center;
  text-align: center;
}

.header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  background-image: url("https://d1dlw0u96vqtxd.cloudfront.net/images/shared/footer-bg-2.png");
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.footer-inner {
  color: white;
  a {
    color: white;
  }
}

.site-map {
  h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
  }
}

.site-map-sub {
  font-size: 12px;
  opacity: 0.8;
  li {
    margin-right: 30px;
    line-height: 1;
    flex-shrink: 0;
    &:last-child {
      margin-right: 0;
    }
  }
}

.footer-appendix {
  .footer-company {
    display: flex;
    align-items: center;
  }
  .copyright {
    font-family: Poppins, sans-serif;
  }
}

.pagination {
  a {
    text-decoration: none;
  }
  .page {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    margin-right: 10px;
    a {
      color: #cccccc;
    }
    &.current {
      a {
        color: #45494b;
      }
    }
  }
  .page,
  .prev,
  .next {
    display: inline-block;
    width: 26px;
    height: 28px;
    text-align: center;
    a {
      display: inline-block;
      width: 26px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border-radius: 1px;
      transition: all 200ms;
    }
    &:not(.current) {
      a:hover {
        background: #e3e3e3;
        color: #45494b;
      }
    }
  }
  .prev,
  .next {
    color: #45494b;
    font-size: 16px;
  }
  .prev {
    margin-right: 10px;
  }
  .first,
  .last {
    display: none;
  }
}

.bar {
  pointer-events: none;
}

select::-ms-expand {
  display: none;
}

@include pc {
  .viewport-small {
    display: none !important;
  }

  .header,
  .footer,
  .sidebar {
    a {
      @include link;
    }
  }

  .header {
    padding: 40px 40px 40px 60px;
    .nav {
      display: flex;
      z-index: 5;
      li {
        padding: 0 20px 0 20px;
      }
      a {
        font-weight: 500;
      }
    }
    .logo {
      img {
        height: 30px;
      }
    }
  }
  .footer {
    width: 100%;
    padding: 80px 30px 60px;
    box-sizing: border-box;
  }
  .footer-inner {
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .bar {
      width: 100%;
      height: 1px;
      opacity: 0.5;
      background: white;
    }
  }

  .site-map {
    width: 100%;
    padding: 0 0 120px 0;
    display: flex;
    justify-content: space-between;

    section {
      flex-shrink: 0;
      flex-grow: 1;
    }
    .section-title {
      margin-bottom: 20px;
    }
    li {
      font-size: 12px;
      line-height: 2.33;
    }
  }

  .site-map-sub {
    ul {
      display: flex;
    }
    margin-bottom: 20px;
  }

  .footer-appendix {
    width: 100%;
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-company {
      font-family: 'Poppins', sans-serif;
    }
    .copyright {
      margin-left: 16px;
      font-size: 12px;
    }
    .sns-buttons {
      display: flex;
      margin-right: 10px;
      li {
        padding-left: 18px;
        font-size: 15px;
      }
    }
  }

  .main-inner {
    max-width: 1280px;
    margin: 0 auto;

    .lead {
      width: 100%;
      padding: 80px 0 100px;
    }

    .lead-main {
      margin: 0;
      padding-bottom: 10px;
      font-family: 'Poppins', sans-serif;
      font-size: 34px;
      font-weight: 600;
      letter-spacing: 6.8px;
      text-indent: 6.8px;
      text-align: center;
      color: #24282a;
    }

    .lead-sub {
      font-size: 16px;
      letter-spacing: 1px;
      text-indent: 1px;
      text-align: center;
      color: #9da0a4;
    }
  }

  .container {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .sidebar {
    width: 180px;
    box-sizing: border-box;
    font-size: 14px;
    z-index: 5;

    position: absolute;
    top: 0;
    li {
      margin-bottom: 30px;
      line-height: 1;
      a {
        color: #9da0a4;
        &.selected {
          color: rgba(0, 0, 0, 0.8);
          font-weight: 600;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.fix {
      position: fixed;
      margin-top: 0;
    }
  }

  .main-content {
    width: 600px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);

    h2 {
      margin: 60px 0 20px;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 2px;
      color: rgba(0, 0, 0, 0.7);
      &:first-child {
        margin-top: 20px;
      }
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 2;
      letter-spacing: 1.2px;
    }
    .list-item {
      display: flex;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .list-head,
    .list-data {
      font-size: 14px;
      line-height: 1.57;
    }
    .list-head {
      width: 100px;
      margin-right: 32px;
      text-align: right;
      color: rgba(0, 0, 0, 0.4);
    }
    .list-data {
      width: 450px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .sidebar-nav {
    display: none;
  }
}

@include mobile-tablet {
  .viewport-large {
    display: none !important;
  }

  .header {
    position: relative;
    width: 100%;
    height: $nav-height;
    z-index: 20;
    background: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0);
    transition: box-shadow 250ms ease-out;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &.sticky {
      position: fixed;
      top: -$nav-height;
      left: 0;
      transition: top 100ms linear;
      .logo .normal {
        opacity: 0;
      }
      &.visible {
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08);
        top: 0;
      }
    }
    &.open {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      .menu-icon {
        .wt-icon-bers-o-1 {
          opacity: 0;
        }
        .wt-icon-close-o-1 {
          opacity: 1;
        }
      }
    }
    .logo {
      height: 24px;
      padding-top: 2px;
      position: relative;
      img {
        height: 22px;
        position: absolute;
        top: 5px;
        left: 0;
        padding-left: 18px;
      }
      .normal {
        transition: opacity 150ms;
        opacity: 1;
      }
    }
    .menu-icon {
      position: relative;
      width: $nav-height;
      height: $nav-height;
      > i {
        position: absolute;
        top: 0;
        left: 0;
        width: $nav-height;
        height: $nav-height;
        line-height: $nav-height;
        display: block;
        text-align: center;
        font-size: 21px;
        transition: opacity 250ms ease;
      }
      .wt-icon-close-o-1 {
        opacity: 0;
      }
    }
  }
  .mobile-nav-wrapper {
    position: fixed;
    top: $nav-height;
    left: 0;
    width: 100%;
    height: calc(100% - #{$nav-height});
    z-index: 15;
    overflow: hidden;
    pointer-events: none;
  }
  .mobile-nav {
    position: absolute;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100%;
    background: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    transition: transform 250ms ease-out;
    pointer-events: auto;
    &.open {
      transform: translate3d(-300px, 0, 0);
    }
    .head-block {
      padding: 38px 0 38px 25px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    }
    .copy {
      margin-top: 20px;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.5px;
      line-height: 1.57;
      color: rgba(0, 0, 0, 0.3);
    }
    .logo {
      height: 26px;
      img {
        width: 180px;
        height: auto;
      }
    }
    .nav-block {
      padding: 38px 0 0 25px;
      li {
        margin-bottom: 10px;
        padding: 10px 0;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 1;
        a {
          color: rgba(0, 0, 0, 0.6);
          &:hover {
            text-decoration: none;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .bottom-block {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 0 23px 25px;
    }
    .copyright {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 300;
      color: rgba(0, 0, 0, 0.6);
    }

    @media screen and (max-width: 320px) {
      width: 250px;
      right: -250px;
      &.open {
        transform: translate3d(-250px, 0, 0);
      }
      .logo {
        height: 24px;
        img {
          width: 170px;
          height: auto;
        }
      }
      .copy {
        font-size: 13px;
      }
      .head-block {
        padding: 34px 0 34px 25px;
      }
      .nav-block {
        padding: 15px 0 0 25px;
        li {
          font-size: 13px;
          margin-bottom: 0;
        }
      }
      .bottom-block {
        padding: 0 0 15px 25px;
      }
      .copy-right {
        font-size: 13px;
      }
    }
  }

  .main {
    transition: opacity 250ms ease-out;
    &.home {
      margin-top: 0;
      padding-top: 0;
    }
    &.nav-open {
      opacity: 0.2;
    }
    &.nav-sticky {
      margin-top: $nav-height !important;
    }
  }
  .main-inner {
    width: 100%;
    margin: 0 auto;

    .lead {
      width: 100%;
      padding: 112px 0 60px;
    }

    .lead-main {
      margin: 0;
      font-family: 'Poppins', sans-serif;
      font-size: 26px;
      font-weight: 600;
      letter-spacing: 3.0px;
      text-align: center;
      color: rgba(0, 0, 0, 0.7);
    }

    .lead-sub {
      font-size: 12px;
      letter-spacing: 0.8px;
      text-align: center;
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .main-content {
    width: 85%;
    margin: 0 auto;
    h2 {
      margin: 60px 0 30px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 1px;
      color: rgba(0, 0, 0, 0.7);
      &:first-child {
        margin-top: 0;
      }
    }
    p {
      margin: 0;
      font-size: 13px;
      line-height: 1.85;
      letter-spacing: 0.8px;
      color: rgba(0, 0, 0, 0.6);
    }
    .list-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .list-head,
    .list-data {
      font-size: 14px;
      line-height: 1.57;
    }
    .list-head {
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.4);
    }
    .list-data {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .footer {
    transition: opacity 250ms ease-out;
    &.nav-open {
      opacity: 0.2;
      .footer-inner {
        pointer-events: none;
      }
    }
  }
  .site-map {
    padding-top: 20px;
    .section-title {
      position: relative;
      padding: 20px;
      border-top: solid 1px rgba(255, 255, 255, 0.2);
      border-bottom: solid 1px rgba(255, 255, 255, 0.2);
      line-height: 1;
      justify-content: space-between;
    }
    section {
      &.is-expanded {
        .opener {
          transform: rotateZ(45deg);
        }
      }
      &:first-child {
        .section-title {
          border-top: none;
        }
      }
    }
    ul {
      height: 0;
      transition: height 300ms ease;
      overflow-y: hidden;
    }
    li {
      font-size: 13px;
      line-height: 3.38;
      a {
        padding-left: 20px;
        display: block;
      }
    }
    .opener {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 16px;
      transition: transform 100ms ease;
    }
  }
  .site-map-sub {
    margin-left: 20px;
    padding-top: 50px;
    justify-content: center;
    border-top: solid 1px rgba(255, 255, 255, 0.2);
    word-break: keep-all;
    li {
      margin-bottom: 28px;
    }
  }
  .footer-appendix {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 90px 20px 0;
    padding-bottom: 25px;
    .sns-buttons {
      display: flex;
      width: 108px;
      justify-content: space-between;
      li {
        font-size: 20px;
      }
    }
    .footer-company {
      text-align: center;
    }
    .copyright {
      font-size: 11px;
      margin-left: 12px;
    }
  }

  .sidebar-wrapper {
    position: absolute;
    top: $nav-height;
    z-index: 19;
    width: 100%;
    background-color: #f9f9f9;
    &.sticky {
      position: fixed;
      top: 0;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08);
    }
  }
  .sidebar {
    padding: 0 32px;
    overflow: auto;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    &.no-scroll {
      text-align: center;
    }
    > li {
      display: inline-block;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    a {
      display: block;
      padding: 0 9px;
      line-height: $nav-height;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.5);
      &.selected {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }
  .sidebar-nav {
    position: absolute;
    top: 0;
    width: 32px;
    height: 100%;
    padding: 0 7px;
    font-size: 16px;
    background-color: #f9f9f9;
    color: rgba(0, 0, 0, 0.5);
    border: none;
    box-shadow: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &.prev,
    &.next {
      i {
        display: inline-block;
        margin-bottom: 2px;
      }
    }
    &.prev {
      left: 0;
      transform: rotate(180deg);
    }
    &.next {
      right: 0;
    }
    &.disabled {
      color: rgba(0, 0, 0, 0.1);
    }
  }
  .footer {
    background-size: 1000px;
  }
}

@include tablet {
  .main-content {
    width: 80%;
  }
}

.flash {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  &.flash-danger{
    background-color: lightcoral;
  }
  &.flash-info {
    background-color: darkseagreen;
  }
}
