@import "./mixins.scss";

#contact-new {
  #contact-form {
    display: flex;
    justify-content: center;

    form {
      @include pc {
        width: 500px;
      }
      @include mobile-tablet {
        width: 100%;
        margin-right: 17px;
        margin-left: 17px;
      }
    }
  }

  .field {
    @include pc {
      margin-bottom: 60px;
    }
    @include mobile-tablet {
      margin-bottom: 40px;
    }
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-bottom: 80px;
    }

    .field_with_errors {
      width: 100%;
      display: flex;

      label {
        color: #ef4343;
        font-weight: 500;
      }
    }

    label {
      width: 100%;
      color: rgba(0, 0, 0, 0.6);
      @include pc {
        margin-bottom: 30px;
        font-size: 14px;
      }
      @include mobile-tablet {
        margin-bottom: 20px;
        font-size: 13px;
      }
    }

    input[type="text"],
    input[type="email"],
    select,
    textarea {
      width: 100%;
      margin: 0;
      padding: 14px 13px;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.6;
      background-color: rgba(0, 0, 0, 0.02);
      color: rgba(0, 0, 0, 0.7);
      border: none;
      outline: none;
      appearance: none;

      &:placeholder-shown {
        color: rgba(0, 0, 0, 0.2);
      }
      &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.2);
      }
      &::-moz-placeholder {
        color: rgba(0, 0, 0, 0.2);
      }
      &::-moz-placeholder {
        color: rgba(0, 0, 0, 0.2);
      }
      &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  input[type="text"],
  input[type="email"],
  select {
    height: 50px;
  }

  textarea {
    height: 200px;
  }
  .select {
    position: relative;
    i {
      position: absolute;
      @include pc {
        top: 69px;
      }
      @include mobile-tablet {
        top: 59px;
      }
      right: 20px;
      font-size: 16px;
      pointer-events: none;
    }
  }
  select {
    cursor: pointer;
  }

  .recaptcha {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .button-block {
    margin-top: 69px;
    display: flex;
    justify-content: center;
  }
  .btn {
    border: none;
  }

  .text-block {
    @include pc {
      margin: 45px auto 120px;
      width: 460px;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.71;
      text-align: center;
      color: #575b5e;
    }
    @include mobile-tablet {
      margin-top: 40px;
      margin-right: 17px;
      margin-left: 17px;
      margin-bottom: 120px;

      font-weight: 300;
      font-size: 13px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.85;
      letter-spacing: normal;
      color: #575b5e;
    }
  }
}

#contact-create {
  .main-content {
    margin-top: 80px;
    text-align: center;
  }
}
