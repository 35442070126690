@import "./mixins.scss";

.main-inner.news {
  position: relative;
  .bar {
    position: absolute;
    top: -330px;
    left: calc(50% - 330px);
    z-index: 2;
  }
}

#news-index,
#home-section-news {
  .entries-container {
    display: flex;
    flex-wrap: wrap;
  }

  .entry-item {
    display: block;
    background: white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
    transition: all 300ms;
    &:hover {
      transform: translateY(-6px);
      box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1);
      text-decoration: none;
    }
  }
  .entry-header {
    font-family: Poppins, sans-serif;
    line-height: normal;
    .category {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      &.press_release {
        color: #943ce1;
      }
      &.info {
        color: #1dace6;
      }
      &.medium {
        color: #1ecc6f;
      }
    }
    .date {
      font-size: 13px;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .entry-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    letter-spacing: 0.6px;
    color: #45494b;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .entry-content {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.83;
    color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .entries-container {
    width: 960px;
  }
  .entry-item {
    width: 300px;
    height: 250px;
    margin-right: 20px;
    margin-bottom: 26px;
    padding: 20px;
    box-sizing: border-box;
  }
  .entry-title {
    height: 45px;
    margin-top: 30px;
  }
  .entry-content {
    margin-top: 15px;
    height: 66px;
  }

  @include pc-narrow {
    .main-content {
      padding: 0 30px;
    }
    .entries-container {
      width: 100%;
      max-width: 960px;
      justify-content: space-between;
    }
    .entry-item {
      width: 30%;
      margin-right: 0;
    }
  }

  @include mobile-tablet {
    .entries-container {
      width: auto;
      margin: 0 27px;
    }
    .entry-item {
      width: 100%;
      margin: 0 auto 26px;
      padding: 30px;
    }
    .entry-title {
      height: 45px;
      margin-top: 20px;
    }
    .entry-content {
      margin-top: 10px;
      height: 66px;
    }
    .select-box-container {
      margin: 0 27px 34px;
    }
  }
}

#news-index {
  .pagination-wrapper {
    display: flex;
    justify-content: center;
    margin: 34px 0 120px;
  }
  .no-entry {
    margin-bottom: 120px;
    padding-top: 40px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
  }

  @include pc {
    .main-content {
      width: 960px;
    }
  }
}

#news-entry {
  .main-content {
    width: 700px;
  }
  .entry-header {
    font-family: Poppins, sans-serif;
    line-height: normal;
    .category {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.1px;
      &.press_release {
        color: #943ce1;
      }
      &.info {
        color: #1dace6;
      }
      &.medium {
        color: #1ecc6f;
      }
    }
    .date {
      font-size: 16px;
      line-height: normal;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .entry-title {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 1px;
    color: #45494b;
  }
  .entry-image {
    margin-top: 30px;
    max-width: 100%;
  }
  .entry-content {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71;
    color: rgba(0, 0, 0, 0.6);

    p + p {
      margin-top: 1em;
    }
    li {
      list-style: disc;
      margin-left: 1.5em;
    }
    a {
      @include link;
      word-break: break-all;
    }
    a,
    a:hover {
      text-decoration: underline;
    }
  }
  .btn-wrapper {
    margin: 80px 0 120px;
    display: flex;
    justify-content: center;
  }

  @include mobile-tablet {
    .main-content {
      width: auto;
      margin: 0 27px;
    }
    .entry-content {
      p {
        letter-spacing: 1px;
      }
    }
  }
}

#admin-news-index,
#admin-presentations-index {
  .lead {
    padding-bottom: 20px;
  }
  .btn-wrapper {
    margin: 20px;
    display: flex;
    justify-content: center;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 100px;
  }
  th {
    text-align: left;
  }
  tr {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  .pagination-wrapper {
    display: flex;
    justify-content: center;
    margin: 34px 0 120px;
  }
}

#admin-presentations-new,
#admin-presentations-edit,
#admin-news-new,
#admin-news-edit {
  .main-content {
    width: 640px;
    font-size: 14px;
  }
  .lead {
    padding: 50px 0;
  }
  .form-item {
    display: flex;
    margin-bottom: 25px;
    flex-wrap: wrap;
    a {
      color: #00a3bc;
    }
  }
  .tags-wrapper {
    display: flex;
    flex-direction: column;
    input {
      margin-right: 4px;
    }
  }
  label {
    width: 170px;
    margin-right: 30px;
    flex-shrink: 0;
  }
  input[type="text"],
  textarea {
    width: 400px;
    max-width: 400px;
    line-height: 1.5;
  }
  input[type="file"] {
    font-size: 12px;
  }
  .actions {
    display: flex;
    justify-content: center;
    margin: 50px 0 80px;
  }
  .btn {
    border: none;
  }
}
#admin-news-links {
  .link-list {
    width: 300px;
    margin: 0 auto 120px;
    font-size: 18px;
  }
  .item-wrapper {
    margin-bottom: 16px;
    a {
      color: #00a3bc;
    }
  }
}
