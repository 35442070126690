@import "./mixins.scss";
$gray: rgba(0, 0, 0, 0.56);
$gold: #B3A16B;
$silver: #A9AFB3;
$bronze: #855A3D;

// reset & variables
.fuze-2020 {
  br {
    &.fuze-2020__visible-mobile {
      display: none;
      @include mobile-tablet {
        display: inline;
      }
    }
    &.fuze-2020__hidden-mobile {
      display: inline;
      @include mobile-tablet {
        display: none;
      }
    }
  }

  /* Box sizingの定義 */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  --black-alpha50: rgba(0, 0, 0, 0.03);
  --black-alpha300: rgba(0, 0, 0, 0.24);
  --black-alpha100: rgba(0, 0, 0, 0.06);
  --black-alpha200: rgba(0, 0, 0, 0.12);
  --black-alpha400: rgba(0, 0, 0, 0.4);
  --black-alpha500: rgba(0, 0, 0, 0.56);
  --black-alpha700: rgba(0, 0, 0, 0.74);
  --black-alpha800: rgba(0, 0, 0, 0.84);
  --white-alpha50: rgba(255, 255, 255, 0.06);
  --white-alpha100: rgba(255, 255, 255, 0.12);
  --white-alpha200: rgba(255, 255, 255, 0.2);
  --white-alpha300: rgba(255, 255, 255, 0.36);
  --white-alpha-400: rgba(255, 255, 255, 0.5);
  --white-alpha500: rgba(255, 255, 255, 0.7);
  --white-alpha700: rgba(255, 255, 255, 0.86);
  --white-alpha800: #ffffff;
  --gray25: #fafafa;
  --gray50: #f5f5f5;
  --gray100: #eeeeee;
  --gray200: #e0e0e0;
  --gray300: #cccccc;
  --gray400: #9e9e9e;
  --gray500: #757575;
  --gray600: #5c5c5c;
  --gray700: #424242;
  --gray800: #292929;
  --aqua-marine: #32bdd9;
  --soft-blue: #618feb;
}

.fuze-2020 {
  min-width: 1400px;
  margin: 0;
  padding: 0 0 93px 0;
  background-color: #0e0e0e;
  color: var(--white-alpha800);
  @include mobile-tablet {
    min-width: unset;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 43px 40px 0;
    @include mobile-tablet {
      padding: 25px 16px 0;
    }
  }
  &__logo {
    height: 30px;
    @include mobile-tablet {
      height: 22px;
    }
  }
  &__logo-image {
    height: 100%;
  }
  &__header-inner {
    display: flex;
    align-items: center;
    @include mobile-tablet {
      display: none;
    }
  }
  &__nav {
    height: 36px;
  }
  &__nav-list {
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__nav-list-item {
    padding-right: 24px;
    height: 100%;
  }
  &__nav-link {
    display: flex;
    align-items: center;
    height: 100%;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--white-alpha500);
  }
  &__header-entry {
    border-radius: 48px;
    background-image: linear-gradient(75deg, #0d93e0, #00c4c4);
    width: 176px;
    height: 36px;
    margin: 0 auto;
    text-align: center;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  &__header-entry-btn {
    display: block;
    padding: 5px 16px 7px;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-alpha800) !important;
    &:hover {
      background-color: rgba(255, 255, 255, 0.06);
    }
    &:active {
      background-color: rgba(255, 255, 255, 0.12)
    }
  }
  &__footer {
    text-align: center;
    padding-bottom: 69px;
    margin: 0 80px;
    @include mobile-tablet {
      margin: 0 66px;
    }
  }
  &__footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile-tablet {

    }
  }
  &__footer-image-wrapper {
    display: flex;
    align-items: center;
    height: 56px;
    padding-right: 32px;
    @include mobile-tablet {
      height: 40px;
      padding-right: 24px;
    }
    & + & {
      align-items: flex-end;
      padding-right: 0;
      padding-left: 32px;
      border-left: 2px solid var(--white-alpha300);
      @include mobile-tablet {
        padding-left: 24px;
      }
    }
  }
  &__footer-image-content {
    &.wantedly {
      height: 30px;
      @include mobile-tablet {
        height: 20px;
      }
    }
    &.fuze {
      object-fit: contain;
      position: relative;
      top: -3px;
      width: 140px;
      height: 36px;
      @include mobile-tablet {
        width: 90px;
        height: 23px;
        top: -4px;
      }
    }
  }
  &__footer-copyright {
    margin: 0;
    padding-top: 48px;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 19px;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-alpha-400);
    @include mobile-tablet {
      padding-top: 32px;
    }
  }
  &__section-mark {
    justify-content: center;
    margin: 0;
    position: relative;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    padding-top: 96px;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: normal;
    text-align: center;
    @include mobile-tablet {
      padding-top: 71px;
      font-size: 16px;
      line-height: normal;
    }
    &::before {
      content: '';
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 80px;
      height: 80px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: auto;
      @include mobile-tablet {
        width: 60px;
        height: 60px;
      }
    }
    &--keynote {
      color: var(--black-alpha800);
      &::before {
        background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/fuze2020/star-small-black.svg');
      }
    }
    &--talk-session {
      margin: 218px 0 0 80px;
      color: var(--white-alpha800);
      display: inline-flex;
      @include mobile-tablet {
        margin: 64px 0 0 16px;
      }
      &::before {
        background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/fuze2020/star-small-white.svg');
      }
    }
    &--awards {
      &::before {
        background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/fuze2020/star-small-white.svg');
      }
    }
    &--time-table {
      display: inline-flex;
      color: var(--white-alpha800);
      &::before {
        background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/fuze2020/star-small-white.svg');
      }
    }
  }
  &__entry {
    z-index: 2;
    margin: 0;
    right: 0;
    left: 0;
    bottom: 24px;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    position: sticky;
    @include mobile-tablet {
      width: 100%;
      max-width: 560px;
      margin: 0 auto;
      padding: 0 16px;
    }
    &--hidden {
      transform: translateY(200px);
      @include mobile-tablet {
        transform: none;
      }
    }
  }
  &__entry-btn {
    border-radius: 48px;
    background-image: linear-gradient(98deg, var(--aqua-marine), var(--soft-blue) 102%);
    width: 292px;
    height: 44px;
    margin: 0 auto;
    text-align: center;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02), 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: block;
    padding: 9px 16px 11px;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-alpha800) !important;
    @include mobile-tablet {
      width: 100%;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.06);
    }
    &:active {
      background-color: rgba(255, 255, 255, 0.12)
    }
  }
}

.fuze-2020-intro {
  min-width: 1400px;
  position: relative;
  @include mobile-tablet {
    min-width: unset;
  }
  &__background-star {
    width: 100%;
    position: absolute;
    right: 0;
    top: -79px;
    z-index: -1;
    @include mobile-tablet {
      top: -132px;
    }
  }
  &__background-star-image {
    width: 100%;
  }
  &__inner {
    max-width: 1440px;
    margin: auto;
    padding-top: 188px;
    padding-bottom: 40px;
    position: relative;
    @include mobile-tablet {
      max-width: 560px;
      text-align: center;
      padding-top: 150px;
      padding-bottom: 108px;
    }
  }
  &__heading {
    padding-left: 80px;
    @include mobile-tablet {
      padding: 0 16px;
    }
  }
  &__heading-copy {
    margin: 0;
    width: 460px;
    height: 117px;
    @include mobile-tablet {
      width: auto;
      height: auto;
    }
  }
  &__heading-copy-image {
    width: 100%;
    @include mobile-tablet {
      vertical-align: bottom;
    }
  }
  &__heading-text {
    margin: 0;
    padding-top: 32px;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.25px;
    color: var(--white-alpha-400);
    @include mobile-tablet {
      padding-top: 24px;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 2px;
      color: var(--white-alpha800);
    }
  }
  &__schedule {
    margin: 0;
    padding-left: 80px;
    padding-top: 64px;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 36px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: var(--white-alpha800);
    @include mobile-tablet {
      padding-left: 0;
      padding-top: 60px;
      font-size: 24px;
      line-height: 1.33;
    }
  }
  &__schedule-time {
    padding-top: 8px;
    display: block;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--white-alpha800);
    @include mobile-tablet {
      font-weight: 300;
    }
  }
  &__scroll-link-wrapper {
    display: none;
    @include mobile-tablet {
      display: block;
      text-align: center;
      padding-top: 20px;
    }
  }
  &__scroll-link {
    display: block;
    margin: auto;
    border-radius: 100%;
    width: 36px;
    height: 36px;
    background-color: var(--white-alpha800);
    position: relative;
    &::before {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/fuze2020/icon-chevron-down@3x.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.97);
      color: rgba(0, 0, 0, 0.53);
    }
  }
  &__lead {
    padding: 250px 80px 0;
    @include mobile-tablet {
      padding: 256px 16px 0;
      text-align-last: left;
    }
  }
  &__lead-heading {
    margin: 0;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 40px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -1.85px;
    color: var(--white-alpha800);
    @include mobile-tablet {
      text-align: left;
      font-size: 42px;
      line-height: 1.33;
      letter-spacing: normal;
    }
  }
  &__lead-text {
    text-align: justify;
    text-align-last: left;
    margin: 0;
    padding-top: 34px;
    width: 600px;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: var(--white-alpha500);
    @include mobile-tablet {
      padding-top: 48px;
      width: auto;
      color: var(--white-alpha-400);
    }
  }
  &__catchword {
    width: 100%;
    vertical-align: top;
    padding-top: 64px;
    line-height: 0;
  }
  &__catchword-image {
    width: 100%;
  }
}

.fuze-2020-keynote {
  min-width: 1400px;
  padding: 120px 0;
  background-color: var(--gray200);
  @include mobile-tablet {
    min-width: unset;
    padding: 64px 0 16px;
    overflow: hidden;
  }
  &__catch {
    max-width: 1440px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 80px;
    @include mobile-tablet {
      flex-direction: column;
      padding: 0 18px;
      max-width: 560px;
    }
  }
  &__catch-time {
    display: none;
    @include mobile-tablet {
      padding-top: 4px;
      display: block;
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.84);
    }
    &--emphasis {
      @include mobile-tablet {
        font-weight: 600;
      }
    }
  }
  &__catch-heading {
    font-feature-settings: initial;
    margin: 0;
    padding-left: 40px;
    white-space: nowrap;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 56px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: var(--black-alpha800);
    @include mobile-tablet {
      font-feature-settings: unset;
      padding: 32px 0 0 0;
      width: auto;
      white-space: unset;
      font-size: 32px;
      font-weight: 700;
      line-height: 1.38;
    }
  }
  &__catch-lead {
    text-align: justify;
    text-align-last: left;
    margin: 0;
    padding-left: 8px;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: var(--black-alpha800);
    @include mobile-tablet {
      padding: 16px 0 0 0;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.6;
    }
  }
  &__catch-lead-text {
    &--hidden-mobile {
      @include mobile-tablet {
        display: none;
      }
    }
  }
  &__body {
    max-width: 1440px;
    margin: auto;
    position: relative;
    padding-top: 94px;
    @include mobile-tablet {
      padding: 72px 0 0 0;
      max-width: 560px;
    }
  }
  &__body-front-content {
    margin: 0;
    white-space: nowrap;
    z-index: 1;
    position: absolute;
    bottom: 389px;
    right: 0;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 160px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.2;
    letter-spacing: -5px;
    text-align: center;
    color: var(--white-alpha800);
    @include mobile-tablet {
      text-orientation: sideways;
      writing-mode: vertical-rl;
      bottom: 196px;
      right: 26px;
      font-size: 110px;
      letter-spacing: 0;
    }
  }
  &__body-front-content-text {
    @include mobile-tablet {
      display: none;
    }
  }
  &__body-inner {
    display: flex;
    align-items: flex-end;
    @include mobile-tablet {
      flex-direction: column-reverse;
      padding: 0 16px 0 0;
    }
    & + & {
      margin-top: -80px;
      flex-direction: row-reverse;
      @include mobile-tablet {
        flex-direction: column-reverse;
        padding: 0 0 0 16px;
        align-items: flex-start;
        margin-top: 64px;

      }
    }
  }
  &__body-text {
    margin-bottom: 202px;
    width: 450px;
    border-bottom: 2px solid var(--black-alpha800);
    margin-right: -59px;
    padding-bottom: 32px;
    @include mobile-tablet {
      width: 343px;
      height: 200px;
      padding: 0 0 0 16px;
      margin: 0 0 -82px 16px;
      border-bottom: none;
      border-left: 2px solid var(--black-alpha800);
    }
    &--align-right {
      text-align: right;
      margin-left: -59px;
      margin-right: auto;
      @include mobile-tablet {
        padding: 0 16px 0 0;
        margin: 0 16px -82px 0;
        border-left: none;
        border-right: 2px solid var(--black-alpha800);
      }
    }
  }
  &__body-image {
    width: 840px;
    height: 550px;
    @include mobile-tablet {
      width: 100%;
      height: auto;
    }
  }
  &__body-image-content {
    width: 100%;
    vertical-align: bottom;
    @include mobile-tablet {
      height: 100%;
    }
  }
  &__body-heading {
    margin: 0;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--black-alpha800);
    @include mobile-tablet {
      font-size: 32px;
    }
  }
  &__body-note {
    margin: 0;
    padding-top: 16px;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--black-alpha400);
    @include mobile-tablet {
      padding-top: 4px;
      font-family: Poppins;
      line-height: normal;
    }
  }
}

.fuze-2020-talk-session {
  min-width: 1400px;
  position: relative;
  @include mobile-tablet {
    min-width: unset;
  }
  &__wrapper {
    position: sticky;
    top: 0;
    overflow: hidden;
  }
  &__sections {
    // height: 100vh;
    display: flex;
  }
  &__section {
    // margin-top: auto;
    // margin-bottom: auto;
    position: relative;
    padding-left: 80px;
    @include mobile-tablet {
      padding-left: 16px;
    }
    & + & {
      padding: 0 80px 0 216px;
      @include mobile-tablet {
      padding: 0 16px 0 128px;
      }
    }
  }
  &__section-background-content {
    position: absolute;
    top: 94px;
    width: 447px;
    height: 494px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    @include mobile-tablet {
      top: 20px;
    }
    &--session-a {
      background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/fuze2020/text-a.svg');
      left: -110px;
      @include mobile-tablet {
        left: -40px;
      }
    }
    &--session-b {
      background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/fuze2020/text-b.svg');
      left: -480px;
      @include mobile-tablet {
        left: 79px;
        width: 359px;
        height: 493px;
      }
    }
  }
  &__section-info {
    border-bottom: 8px solid var(--white-alpha800);
    padding: 61px 0 32px;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--white-alpha800);
    @include mobile-tablet {
      border-bottom-width: 4px;
      padding: 34px 0 4px;
      font-size: 16px;
      line-height: 2;
    }
  }
  &__section-info-item {
    &:last-of-type {
      padding-left: 24px;
      @include mobile-tablet {
        padding-left: 16px;
      }
    }
  }
  &__section-group {
    padding-top: 72px;
    display: flex;
    align-items: center;
    @include mobile-tablet {
      padding-top: 32px;
    }
  }
  &__catch {
    width: 416px;
    @include mobile-tablet {
      width: 260px;
    }
  }
  &__catch-heading {
    margin: 0;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--white-alpha800);
    @include mobile-tablet {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.56;
    }
  }
  &__catch-text {
    text-align: justify;
    text-align-last: left;
    margin: 0;
    padding-top: 24px;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--white-alpha-400);
    @include mobile-tablet {
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: 0.6px;
    }
  }
  &__block {
    display: flex;
    align-items: center;
    padding-left: 80px;
    @include mobile-tablet {
      align-items: unset;
      flex-direction: column-reverse;
      padding-left: 64px;
    }
  }
  &__block-main {
    width: 320px;
    border-bottom: 2px solid var(--white-alpha800);
    padding-bottom: 32px;
    margin-right: -63px;
    z-index: 0;
    @include mobile-tablet {
      width: 200px;
      border-bottom-width: 1px;
      margin: 0;
      padding-top: 21px;
    }
  }
  &__logo {
    padding-bottom: 32px;
    line-height: 0;
    @include mobile-tablet {
      padding: 0;
      height: 50px;
      display: flex;
      align-items: center;
    }
    &--salesforce {
      width: 100px;
      @include mobile-tablet {
        width: 60px;
      }
    }
    &--slack {
      width: 133px;
      @include mobile-tablet {
        width: 78px;
      }
    }
    &--wantedly {
      width: 155px;
      @include mobile-tablet {
        width: 93px;
      }
    }
    &--fullkaiten {
      width: 80px;
      @include mobile-tablet {
        width: 48px;
      }
    }
    &--sansan {
      width: 110px;
      @include mobile-tablet {
        width: 66px;
      }
    }
    &--note {
      width: 125px;
      @include mobile-tablet {
        width: 75px;
      }
    }
    &--goodpatch {
      width: 150px;
      @include mobile-tablet {
        width: 90px;
      }
    }
  }
  &__logo-image {
    width: 100%;
  }
  &__block-heading {
    margin: 0;
    position: relative;
    padding-top: 32px;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--white-alpha800);
    @include mobile-tablet {
      padding-top: 21px;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 32px;
      height: 2px;
      background-color: var(--white-alpha300);
      @include mobile-tablet {
        display: none;
      }
    }
  }
  &__block-note {
    margin: 0;
    padding-top: 16px;
  }
  &__block-note-item {
    display: block;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--white-alpha300);
    @include mobile-tablet {
      font-size: 12px;
      line-height: 1.33;
      & + & {
        margin-top: 4px;
      }
      &--wantedly,
      &--fullkaiten,
      &--sansan,
      &--note,
      &--goodpatch {
        padding-bottom: 1.33em;
      }
    }
    &--bright {
      font-weight: 600;
      color: var(--white-alpha800);
      @include mobile-tablet {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
      }
    }
  }
  &__block-image {
    min-width: 500px;
    min-height: 500px;
    height: calc(100vh - 412px);
    @include mobile-tablet {
      width: calc(100vh - 412px);
      max-width: 200px;
      max-height: 200px;
      min-width: unset;
      min-height: unset;
    }
  }
  &__block-image-content {
    position: relative;
    width: 500px;
    height: 500px;
    top: calc(50% - 250px);
    @include mobile-tablet {
      width: 100%;
      height: 100%;
      position: static;
    }
  }
}

.fuze-2020-awards {
  min-width: 1400px;
  padding-bottom: 329px;
  margin-top: 200px;
  margin-bottom: -329px;
  overflow: hidden;
  position: relative;
  @include mobile-tablet {
    min-width: unset;
  }
  &__image-wrapper {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 1440px;
    height: 100vh;
    overflow: hidden;
    @include mobile-tablet {
      min-width: unset;
    }
  }
  &__image-cover {
    position: absolute;
    height: calc(50vh - 29vw);
    background-color: #0e0e0e;
    width: 100%;
    transform: scale(1);
    &--top {
      top: 0;
    }
    &--bottom {
      bottom: 0;
    }
  }
  &__image {
    width: 100%;
    transform: scale(1);
    z-index: 1;
  }
  &__lead {
    position: relative;
    // position: sticky;
    // top: 0;
    text-align: center;
  }
  &__lead-inner {
    width: 100%;
    min-width: 1440px;
    height: 100vh;
    background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/fuze2020/team-eyecatch@2x.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include mobile-tablet {
      background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/fuze2020/team-eyecatch-mobile@2x.png');
      min-width: unset;
    }
  }
  &__lead-inner-texts {
    opacity: 0;
    position: relative;
    top: calc(50% - 293px);
    @include mobile-tablet {
      padding: 0 16px;
      max-width: 560px;
      margin: auto;
    }
  }
  &__lead-front-content {
    z-index: 1;
    padding-left: 0.7em;
    position: absolute;
    bottom: -580px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: baseline;
    white-space: nowrap;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 300px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.34;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-alpha50);
    @include mobile-tablet {
      padding-left: 0;
      font-size: 70px;
      bottom: -500px;
    }
  }
  &__body {
    position: relative;
  }
  &__body-background-star {
    z-index: -1;
    position: absolute;
    width: 1510px;
    height: 1510px;
    background-image: url('https://dubpy8abnqmkw.cloudfront.net/images/corporate_site/fuze2020/star-big-gray.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @include mobile-tablet {
      width: 362px;
      height: 362px;
    }
    &:nth-of-type(1) {
      left: -963px;
      top: 132px;
      @include mobile-tablet {
        left: -181px;
        top: 326px;
      }
    }
    &:nth-of-type(2) {
      right: -927px;
      top: 1052px;
      @include mobile-tablet {
        right: -181px;
        top: 807px;
      }
    }
    &:nth-of-type(3) {
      left: -963px;
      top: 2312px;
      @include mobile-tablet {
        left: -181px;
        top: 1303px;
      }
    }
  }
  &__body-inner {
    max-width: 1024px;
    margin: auto;
    padding: 213px 0 200px;
    @include mobile-tablet {
      padding: 144px 16px 160px;
    }
  }
  &__lead-main {
    margin: 0;
    padding-top: 48px;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 40px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: -1.85px;
    text-align: center;
    color: var(--white-alpha800);
    @include mobile-tablet {
      padding-top: 32px;
      font-size: 32px;
      line-height: 1.38;
      letter-spacing: normal;
    }
    @media screen and (max-width: 320px) {
      font-size: 28px;
    }
  }
  &__lead-note {
    text-align: justify;
    text-align-last: left;
    margin: 0;
    padding-top: 24px;
    width: 600px;
    margin: auto;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: var(--white-alpha800);
    @include mobile-tablet {
      padding-top: 26px;
      width: auto;
      font-size: 15px;
      line-height: 2.13;
      letter-spacing: 0.47px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    @include mobile-tablet {
      max-width: 560px;
      margin: auto;
    }
  }
  &__list-catch {
    text-align: center;
  }
  &__list-catch-heading {
    margin: 0;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 56px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-alpha800);
    @include mobile-tablet {
      font-size: 48px;
      line-height: 1.17;
    }
  }
  &__list-catch-text {
    margin: 0;
    padding-top: calc(2px + 0.5em);
    width: 726px;
    margin: auto;
    margin-bottom: -0.5em;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: var(--white-alpha800);
    @include mobile-tablet {
      text-align: justify;
      text-align-last: left;
      padding-top: 16px;
      width: auto;
      margin-bottom: 0;
      font-size: 15px;
      font-weight: 600;
      line-height: 2.13;
      letter-spacing: 0.47px;
      color: var(--white-alpha700);
    }
  }
  &__list-item-container {
    padding-top: 64px;
    @include mobile-tablet {
      padding-top: 121px;
    }
  }
  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: -73px;
    @include mobile-tablet {
      flex-direction: column;
      position: relative;
      margin-right: 0;
    }
    &--reverse {
      flex-direction: row-reverse;
      margin-right: 0;
      @include mobile-tablet {
        flex-direction: column;
      }
    }
    & + & {
      margin-top: 24px;
      @include mobile-tablet {
        margin-top: 120px;
      }
    }
  }
  &__list-item-image-wrapper {
    width: 744px;
    height: 650px;
    margin-left: -128px;
    @include mobile-tablet {
      width: 447px;
      height: 390px;
      margin-left: 0;
      left: -119px;
      position: absolute;
      @media screen and (max-width: 320px) {
        left: -175px;
      }
    }
    &--negative-right {
      margin-left: 0;
      margin-right: -128px;
      @include mobile-tablet {
        margin-right: 0;
        left: unset;
        right: -121px;
      }
      @media screen and (max-width: 320px) {
        right: -175px;
      }
    }
    &--lancers {
      @include mobile-tablet {
        margin-top: 16px;
      }
    }
  }
  &__list-item-image {
    width: 100%;
  }
  &__list-item-inner {
    width: 382px;
    @include mobile-tablet {
      width: auto;
    }
  }
  &__list-item-logo {
    &--freee {
      width: 299px;
      height: 101px;
      @include mobile-tablet {
        width: 240px;
        height: 81px;
        margin-bottom: 309px;
      }
    }
    &--mybest {
      width: 240px;
      height: 71px;
      @include mobile-tablet {
        width: 192px;
        height: 57px;
        margin-bottom: 333px;
        margin-left: auto;
      }
    }
    &--lancers {
      width: 270px;
      height: 50px;
      @include mobile-tablet {
        width: 216px;
        height: 40px;
        margin-bottom: 366px;
      }
    }
  }
  &__list-item-logo-image {
    width: 100%;
  }
  &__list-item-title {
    margin: 0;
    padding-top: 40px;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: normal;
    color: var(--white-alpha800);
    @include mobile-tablet {
      padding-top: 0;
      font-size: 20px;
      line-height: 1.4;
    }
  }
  &__list-item-text {
    text-align: justify;
    text-align-last: left;
    margin: 0;
    padding-top: 8px;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--white-alpha-400);
    @include mobile-tablet {
      font-size: 14px;
      line-height: 1.57;
    }
  }
  &__unit {
    margin-top: 176px;
    @include mobile-tablet {
      margin: 144px auto 0;
      max-width: 560px;
    }
    & + & {
      margin: 201px 0 232px;
      @include mobile-tablet {
        margin: 120px auto 160px;
        max-width: 560px;
      }
    }
  }
  &__unit-catch {
    padding: 0 0 18px 35px;
    border-left: 2px solid var(--white-alpha800);
    @include mobile-tablet {
      padding: 0 0 0 16px;
    }
  }
  &__unit-catch-heading {
    margin: 0;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 48px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 79px;
    letter-spacing: normal;
    color: var(--white-alpha800);
    @include mobile-tablet {
      font-size: 36px;
      line-height: 1.22;
    }
  }
  &__unit-catch-text {
    text-align: justify;
    text-align-last: left;
    margin: 0;
    width: 382px;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: var(--white-alpha-400);
    @include mobile-tablet {
      width: auto;
      padding-top: 8px;
      font-size: 14px;
      line-height: 1.57;
    }
  }
  &__unit-inner {
    padding-top: 64px;
    display: flex;
    align-items: center;
    @include mobile-tablet {
      padding-top: 88px;
      flex-direction: column;
    }
  }
  &__unit-item {
    & + & {
      margin-left: 96px;
      @include mobile-tablet {
        margin: 64px 0 0 0;
      }
    }
    &--newn {
      width: 270px;
      height: 36px;
      @include mobile-tablet {
        width: 216px;
        height: 29px;
      }
    }
    &--xmile {
      width: 200px;
      height: 53px;
      @include mobile-tablet {
        width: 160px;
        height: 43px;
      }
    }
    &--lull {
      width: 260px;
      height: 47px;
      @include mobile-tablet {
        width: 208px;
        height: 38px;
      }
    }
    &--crassone {
      width: 201px;
      height: 146px;
      @include mobile-tablet {
        width: 161px;
        height: 117px;
      }
    }
    &--stmn {
      width: 280px;
      height: 48px;
      @include mobile-tablet {
        width: 224px;
        height: 39px;
      }
    }
    &--fullkaiten {
      width: 139px;
      height: 140px;
      @include mobile-tablet {
        width: 112px;
        height: 112px;
      }
    }
  }
  &__unit-item-image {
    width: 100%;
  }
  &__schedule {
    border-top: 2px solid var(--white-alpha800);
    padding-top: 208px;
    @include mobile-tablet {
      border-top-width: 1px;
      padding-top: 124px;
      max-width: 560px;
      margin: auto;
    }
  }
  &__schedule-caption {
    margin: 48px 0 0 0;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: var(--white-alpha800);
    @include mobile-tablet {
      margin: 32px 0 0 0;
      font-size: 40px;
      line-height: 1.4;
    }
    &--foot {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 32px;
      @include mobile-tablet {
        margin-top: 16px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  &__schedule-note {
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: var(--white-alpha800);
  }
  &__schedule-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 24px;
    @include mobile-tablet {
      margin-top: 16px;
    }
  }
  &__schedule-table-head {
    padding: 14px 14px 14px 0;
    text-align: left;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--white-alpha-400);
  }
  &__schedule-table-data {
    box-sizing: border-box;
    padding: 14px 0;
    background-color: var(--white-alpha200);
    .fuze-2020-awards__schedule-table-row:not(:first-of-type) & {
      border-top: 8px solid #0e0e0e;
    }
    @include mobile-tablet {
      display: block;
    }
    &:first-of-type {
      border-radius: 1px 0 0 1px;
      min-width: 228px;
      padding-left: 24px;
      padding-right: 24px;
      white-space: nowrap;
      @include mobile-tablet {
        min-width: unset;
        padding: 16px 16px 0 16px;
      }
    }
    &:last-of-type {
      border-radius: 0 1px 1px 0;
      width: 100%;
      @include mobile-tablet {
        padding: 4px 16px 16px 16px;
        .fuze-2020-awards__schedule-table-row:not(:first-of-type) & {
          border-top: none;
        }
      }
    }
  }
  &__schedule-table-info {
    margin: 0;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, "Hiragino Sans", "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ' , Meiryo , Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--white-alpha800);
    @include mobile-tablet {
    }
  }
  &__schedule-table-title {
    margin: 0;
    font-family: 'Hiragino Sans', "ヒラギノ角ゴシック", 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Helvetica Neue', Helvetica, Arial, Roboto, 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--white-alpha800);
    @include mobile-tablet {
      font-size: 14px;
      line-height: 1.71;
      color: var(--white-alpha-400);
    }
  }
}
