@import "./mixins.scss";

.main.home {
  overflow: hidden;
  @include pc {
    margin-top: -120px;
    padding-top: 120px;
  }
}

#home-index {
  .layer-top {
    z-index: 2;
  }
  .layer-middle {
    z-index: 1;
  }
  .layer-bottom {
    z-index: -1;
  }
  .lead {
    padding: 0;
  }
  .section-title {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
  }
  .section-sub {
    text-align: center;
    color: rgba(0, 0, 0, 0.3);
  }

  @include pc {
    .section-title {
      margin: 0 0 10px;
      font-size: 30px;
      letter-spacing: 3px;
    }
    .section-sub {
      font-size: 16px;
      letter-spacing: 1px;
    }
  }

  @include mobile-tablet {
    box-sizing: border-box;
    .section-title {
      margin: 0;
      font-size: 26px;
      letter-spacing: 2.6px;
    }
    .section-sub {
      font-size: 12px;
      letter-spacing: 0.7px;
    }
  }

  @include tablet {
    .section-title {
      font-size: 28px;
    }
    .section-sub {
      font-size: 14px;
    }
  }
}

#home-section-top {
  .image-block {
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 480ms ease-in-out;
      &.show {
        opacity: 1;
      }
    }
  }

  @include pc {
    position: relative;
    height: 850px;
    margin-top: 80px;

    .text-block {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 4%;
      z-index: 2;
      width: 100%;
      box-sizing: border-box;
    }

    .catch {
      width: 50%;
    }

    .message {
      width: 33%;
      margin: 28px 0 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 2.0;
      letter-spacing: 0.3px;
      color: rgba(0, 0, 0, 0.7);
    }

    .image-block {
      position: relative;
      top: 64px;
      margin-left: auto;
      margin-bottom: 60px;
      width: 60%;
      img {
        width: 100%;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
      }
    }

    .w-logo-wrapper {
      width: 63%;
      position: absolute;
      top: -200px;
      left: 30%;
    }

    .w-logo {
      width: 100%;
    }

    .bar {
      position: absolute;
      top: 420px;
      right: 55%;
      width: 18.5%;
    }
  }

  @include mobile-tablet {
    position: relative;
    margin-top: 42px;

    .text-block {
      margin-top: -12px;
      padding: 0 5%;
    }

    .catch {
      width: 100%;
    }

    .message {
      margin: 0;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.83;
      color: rgba(0, 0, 0, 0.6);
    }

    .image-block {
      margin-left: 17%;
      padding-top: 96%;
      z-index: 0;
      img {
        width: 100%;
        box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.1);
      }
    }

    .bar {
      position: absolute;
      top: -20px;
      right: 62%;
      width: 33%;
    }

    .w-logo-wrapper {
      width: 100%;
      position: absolute;
      top: -94px;
      left: 0;
    }

    .w-logo {
      height: 535px;
    }
  }

  @include tablet {
    .text-block {
      margin-top: -100px;
    }
    .message {
      margin: 60px 0 0;
      font-size: 13px;
    }
  }
}

#home-section-products {
  @include pc {
    position: relative;
    height: 1300px;
    margin-top: 150px;

    .lead {
      margin-bottom: 0;
    }

    .image-blocks {
      img {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
        transition: all 300ms;
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.08);
        }
      }
    }

    .image-block-outer {
      margin-bottom: 30px;
      width: 100%;
      display: flex;

      &:nth-child(odd) {
        flex-direction: row;
      }
      &:nth-child(even){
        flex-direction: row-reverse;
      }
    }

    .image-block-inner img {
      width: 800px;
      height: 400px;
    }

    #home-products-visit-intl {
      margin-top: -120px;
    }

    .bg-text {
      width: 845px;
      position: absolute;
      top: 150px;
      left: 100px;
    }
  }

  @include pc-narrow {
    margin-top: 0;

    .bg-text {
      width: 66%;
    }
  }

  @include mobile-tablet {
    margin-top: 120px;
    position: relative;

    .lead {
      margin-bottom: -30px;
    }

    .image-blocks {
      margin-top: 4px;
    }

    .image-block-inner {
      margin-bottom: 23px;
    }

    .image-block-outer {
      &:nth-child(odd) {
        .image-wrapper {
          margin-right: auto;
        }
      }
      &:nth-child(even) {
        .image-wrapper {
          margin-left: auto;
        }
      }
    }

    .image-wrapper {
      display: block;
      width: 85%;
      img {
        width: 100%;
        box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.1);
      }
    }

    .bg-text {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  @include tablet {
    .image-wrapper {
      width: 75%;
    }
  }
}

#home-section-news {
  .button-block {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @include pc {
    margin-top: 120px;

    .lead {
      margin-bottom: 60px;
    }
    .entries-block {
      margin-bottom: 60px;
      display: flex;
      justify-content: center;
      a {
        text-decoration: none;
      }
    }
    .entry-item:last-child {
      margin-right: 0;
    }
  }

  @include pc-narrow {
    .entries-container {
      padding: 0 30px;
    }
  }

  @include mobile-tablet {
    margin-top: 80px;

    .lead {
      margin-bottom: 60px;
    }
    .entries-block {
      margin-bottom: 60px;
    }
  }

  @include tablet {
    margin-top: 110px;
  }
}

#home-section-ir {
  @include pc {
      margin-top: 120px;
    .lead {
      margin-bottom: 56px;
    }
  }
  @include mobile-tablet {
    margin-top: 140px;
    .lead {
      margin-bottom: 60px;
    }
  }

  .button-block {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

#home-section-access {
  position: relative;

  .info-block {
    color: rgba(0, 0, 0, 0.7);
    .tokyo {
      font-family: Poppins, sans-serif;
      font-size: 46px;
      line-height: 0.93;
      letter-spacing: 3.3px;
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 1.7;
      letter-spacing: 1px;
    }
    .office {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
    }
    .bar {
      width: 26px;
      height: 2px;
      margin: 17px 0 20px;
      background: rgba(0, 0, 0, 0.9);
    }
    .address {
      font-weight: 600;
    }
  }
  .btn-wrapper {
    display: flex;
  }
  .copy-btn {
    width: 44px;
    border-radius: 50%;
    flex-shrink: 0;
    i {
      color: white;
      font-size: 20px;
      line-height: 44px;
    }
  }
  .tooltip:after {
    width: 160px;
  }

  @include pc {
    margin: 170px 0 180px;

    .main-block {
      display: flex;
      padding-left: 100px;
    }

    .map-block {
      width: 840px;
      height: 580px;
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
      background-color: rgba(0, 0, 0, 0.06);
    }

    .info-block {
      width: 270px;
      height: 180px;
      margin: 50px 60px 0 0;
      .tokyo {
        margin: 0 0 40px;
      }
      .address {
        margin-bottom: 10px;
      }
    }
    .btn-wrapper {
      margin-top: 120px;
    }
    .copy-btn {
      margin-left: 15px;
    }

    .bg-text {
      position: absolute;
      bottom: -50px;
      left: 0;
      width: 370px;
    }
  }

  @include pc-narrow {
    .main-block {
      padding-right: 30px;
    }
  }

  @include mobile-tablet {
    margin: 140px 0 120px;

    .main-block {
      padding-left: 8%;
    }
    .map-block {
      position: absolute;
      top: 135px;
      right: 0;
      width: 92%;
      height: 280px;
    }
    .bottom-block {
      margin-top: 340px;
    }
    .info-block {
      .tokyo {
        margin: 0;
      }
      .address {
        margin-bottom: 10px;
      }
    }
    .btn-wrapper {
      width: 265px;
      margin: 60px auto 0;
      padding-right: 8%;
    }
    .copy-btn {
      margin-left: 20px;
    }
    .bg-text {
      position: absolute;
      bottom: -50px;
      left: 0;
      width: 80%;
    }
    .tooltip:after {
      width: 265px;
    }
  }
}

#disclaimer {
  .lead {
      padding: 60px 0;
  }
}

#security-policy {
  .lead {
    padding: 60px 0;
  }
  .philosophy {
    margin-bottom: 60px;
  }
  .policy {
    margin-bottom: 60px;
    ul {
      padding-left: 20px;
    }
    li {
      list-style-type: decimal;
      margin-bottom: 8px;
      margin: 0;
      font-size: 14px;
      line-height: 2;
      letter-spacing: 1.2px;
    }
  }
  .signature {
    width: 100%;
    margin-top: 60px;
    font-size: 13px;
  }
  .isms {
    .desc-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .desc {
      margin-bottom: 12px;
    }
    .isms-logo {
      margin-bottom: 40px;
    }
    .info {
      margin-bottom:  12px;
    }
    .key {
      color: rgba(0, 0, 0, 0.4);
      font-size: 14px;
      line-height: 1.57;
      width: 100px;
      margin-bottom: 4px;
    }
    .val {
      color: rgba(0, 0, 0, 0.7);
      font-size: 14px;
      line-height: 1.57;
      font-weight: 600;
    }
  }
  .isms-logo {
    width: 160px;
  }
  @include pc {
    .signature {
      text-align: right;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  @include mobile-tablet {
    .philosophy {
      margin-bottom: 60px;
    }
    .signature {
      text-align: center;
      color: #6a6e71;
      margin-bottom: 60px;
    }
  }
}

#brand_assets {
  * {
    box-sizing: border-box;
  }
  .title {
    font-family: Poppins;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
  }
  .sub-title {
    font-family: Poppins;
    color: rgba(0, 0, 0, 0.56);
  }
  .bar {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.12);
  }
  .guideline {
    .describe {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.56);
      line-height: 1.85;
    }
    .guideline-thumb {
      width: 100%;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.02);
    }
  }
  .logo-icons {
    .item {
      display: flex;
      position: relative;
    }
    .download-icon {
      width: 20px;
      height: 20px;
      position: absolute;
    }
    .assets-name {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.56);
    }
  }
  @include pc {
    .main-content {
      width: 75%;
      max-width: 960px;
    }
    .lead {
      padding-bottom: 108px;
    }
    .title {
      font-size: 40px;
      line-height: 1.1;
      margin-bottom: 8px;
    }
    .sub-title {
      font-size: 16px;
      letter-spacing: 0.6px;
      margin-bottom: 26px;
    }
    .guideline {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 124px;
      .text-block {
        width: 50%;
      }
      .image-block {
        width: 47.5%;
      }
      .title {
        letter-spacing: 1px;
      }
      .bar {
        margin-bottom: 24px;
      }
      .describe {
        width: 92%;
        height: 112px;
      }
      .download {
        display: block;
        border-radius: 100px;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 5px 8px 5px 16px;
        width: 200px;
        height: 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .download-label {
        font-size: 14px;
        color: #fff;
      }
      .download-icon {
        width: 20px;
        height: 20px;
      }
    }
    .logo-icons {
      margin-bottom: 150px;
      .title {
        letter-spacing: 0.6px;
      }
      .bar {
        margin-bottom: 40px;
      }
      .image-block {
        display: flex;
        justify-content: center;
      }
      .item {
        display: flex;
        width: 222px;
        height: 222px;
        margin-right: 12px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 36px 0 16px;
        border-radius: 2px;
        transition: all 300ms;
        &:last-child {
          margin-right: 0;
          padding-top: 30px;
        }
        .download-icon {
          transition: all 300ms;
          opacity: 0;
        }
        .assets-name {
          transition: all 300ms;
          color: #45494b;
        }
        &:hover {
          box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.02);
          transform: translateY(-3px);
          .download-icon {
            opacity: 1;
          }
        }
      }
      .download-icon {
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 16px;
        right: 16px;
      }
      .assets-name {
        letter-spacing: 0.4px;
      }
      .img-wantedly {
        width: 128px;
        margin-top: 12px;
      }
      .img-visit,
      .img-people {
        width: 116px;
      }
    }
  }
  @media screen and (max-width: 1120px) {
    .logo-icons {
      .item {
        width: 160px;
        height: 160px;
        padding-bottom: 30px;
      }
      .assets-image {
        width: auto;
        height: 65%;
      }
      .img-wantedly {
        margin-top: 3px;
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      width: 87%;
    }
    .lead {
      padding: 85px 0 90px;
    }
    .title {
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: 1px;
      margin-bottom: 3px;
    }
    .sub-title {
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.5px;
      margin-bottom: 26px;
    }
    .guideline {
      margin-bottom: 70px;
      .describe {
        margin-bottom: 24px;
      }
      .bar {
        margin-bottom: 24px;
      }
    }
    .logo-icons {
      margin-bottom: 96px;
      .title {
        letter-spacing: 0.3px;
        margin-bottom: 2px;
      }
      .item-wrapper {
        width: 100%;
        height: 96px;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.02);
        margin-bottom: 16px;
      }
      .bar {
        margin-bottom: 32px;
      }
      .item {
        width: 100%;
        height: 100%;
        align-items: center;
        padding-bottom: 0;
      }
      .assets-image {
        margin-left: 31px;
      }
      .assets-name {
        position: absolute;
        left: 32vw;
        top: 32px;
        letter-spacing: 0.1px;
      }
      .download-icon {
        right: 20px;
        top: 40px;
      }
      .img-wantedly {
        height: auto;
        width: 23%;
        max-width: 78px;
        margin-left: 25px;
      }
      .img-people,
      .img-visit {
        height: auto;
        width: 18%;
        max-width: 64px;
      }
    }
  }
}
