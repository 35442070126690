@import "./mixins.scss";

.ir {
  @include pc {
    .main-content {
      min-height: 550px;
    }
    @media screen and (max-width: 1250px) {
      .container {
        padding: 0 60px;
      }
      .main-content {
        margin-left: 200px;
      }
    }
  }
}

#ir-message {
  .bar-wrapper {
    position: relative;
  }
  .main-content h2 {
    margin: 60px 0 30px;
  }
  .text-block {
    margin: 0 auto;
  }
  .image-block {
    position: relative;
  }
  .head-image {
    width: 800px;
  }
  .bar {
    position: absolute;
    z-index: 1;
  }
  .image-shadow {
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  }
  .signature {
    width: 100%;
    margin-top: 60px;
    font-size: 13px;
  }
  @include pc {
    .main-content {
      width: 800px;
      margin-bottom: 120px;
    }
    .text-block {
      width: 600px;
      p {
        font-size: 14px;
      }
    }
    .bar {
      top: -100px;
      left: 644px;
      width: 209px;
    }
    .signature {
      text-align: right;
      color: rgba(0, 0, 0, 0.5);
    }
    @media screen and (max-width: 1250px) {
      .head-image {
        width: 100%;
      }
      .text-block {
        width: 100%;
      }
      .bar {
        top: -12.5%;
        left: 80%;
        width: 25%;
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      max-width: 600px;
      margin-bottom: 120px;
    }
    .image-block {
      width: 100%;
      text-align: center;
    }
    .bar {
      top: -60px;
      right: -20px;
      width: 25%;
    }
    .head-image {
      width: 100%;
      margin: 0 auto;
    }
    .signature {
      text-align: center;
      color: #6a6e71;
    }
  }
}

#ir-news {
}

#ir-financials {
  .main-content {
    margin-bottom: 120px;
  }
  @include pc {
    .main-content {
      width: 600px;
    }
    .qirColumnWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .qirColumn {
      width: auto;
      float: none;
    }
    @media screen and (max-width: 1250px) {
      .qirColumnWrapper {
        margin: 0 auto;
        width: 600px;
      }
    }
    @media screen and (max-width: 1050px) {
      .qirColumnWrapper {
        margin: 0 auto;
        width: 330px;
      }
      .qirColumn {
        width: 100%;
      }
      .highcharts-container {
        width: 288px;
      }
    }
  }
}

#ir-governance {
  @include pc {
    .main-content {
      width: 600px;
      margin-bottom: 120px;
    }
    .report {
      margin: 40px 0 60px;
      font-size: 16px;
    }
    i {
      font-size: 18px;
      margin-right: 5px;
    }
    .graph {
      width: 600px;
    }
    @media screen and (max-width: 1050px) {
      .container {
        padding: 0 60px;
      }
      .graph {
        width: 100%;
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      max-width: 600px;
      margin-bottom: 120px;
    }
    .report {
      margin: 30px 0 50px;
      font-size: 14px;
    }
    i {
      font-size: 16px;
      margin-right: 3px;
    }
    .graph {
      width: 100%;
    }
  }
}

#ir-disclosure-policy {
  .main-content h2 {
    margin: 60px 0 30px;
  }
  .text-block {
    margin: 0 auto;
  }
  .updated-date {
    width: 100%;
    margin-top: 60px;
    font-size: 13px;
  }
  @include pc {
    .main-content {
      width: 800px;
      margin-bottom: 120px;
    }
    .text-block {
      width: 600px;
      p {
        font-size: 14px;
      }
    }
    .updated-date {
      text-align: right;
      color: rgba(0, 0, 0, 0.5);
    }
    @media screen and (max-width: 1250px) {
      .text-block {
        width: 100%;
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      max-width: 600px;
      margin-bottom: 120px;
    }
    .updated-date {
      text-align: center;
      color: #6a6e71;
    }
  }
}

#ir-faq {
  .questions {
    a {
      text-decoration: underline;
    }
  }
  .item {
    position: relative;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
    cursor: pointer;
    &.open {
      .opener {
        transform: rotateZ(45deg);
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .question-block {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    .desc {
      line-height: 1;
    }
  }
  .answer-block {
    height: 0;
    overflow-y: hidden;
    transition: height 300ms;
    cursor: auto;
  }
  .answer-inner {
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    color: #6a6e71;
    background-color: rgba(0, 0, 0, 0.02);
    .desc {
      line-height: 1.7;
      letter-spacing: 1.3px;
    }
  }
  .opener {
    position: absolute;
    font-size: 17px;
    color: #000;
    transition: transform 100ms ease;
  }
  @include pc {
    .main-content {
      width: 800px;
      margin-bottom: 100px;
    }
    .questions {
      width: 800px;
    }
    .answer-inner {
      margin-top: 20px;
      padding: 20px;
      .desc {
        font-size: 15px;
      }
    }
    .item {
      padding: 30px;
      margin-bottom: 20px;
    }
    .opener {
      top: 26px;
      right: 30px;
    }
    @media screen and (max-width: 1250px) {
      .questions {
         width: 100%;
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      width: auto;
      max-width: 600px;
      margin: 0 auto 120px;
      padding: 0 10px;
    }
    .answer-inner {
      margin-top: 30px;
      padding: 20px 15px;
      .desc {
        font-size: 14px;
      }
    }
    .item {
      padding: 30px 20px;
      margin-bottom: 30px;
    }
    .opener {
      top: 30px;
      right: 22px;
    }
  }
}

#ir-meeting,
#ir-stock-price {
  @include pc {
    .lead {
      padding-bottom: 80px;
    }
  }
}

#ir-stock-info,
#ir-meeting,
#ir-stock-price {
  @include pc {
    .main-content {
      min-height: auto;
    }
  }
}

#ir-stock-info {
  @include pc {
    .list-head {
      width: 140px;
    }
    .list-data {
      width: 420px;
    }
    @media screen and (max-width: 1250px) {
      .main-content {
        width: 80%;
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      margin-bottom: 30px;
    }
  }
}

#ir-meeting {
  @include pc {
    margin-top: 40px;
    .item {
      display: flex;
      padding: 34px 0;
      border-bottom: solid 1px;
      border-color: rgba(0, 0, 0, 0.05);
    }
    .col-left {
      margin: auto 30px auto 0;
      color: rgba(0, 0, 0, 0.4);
      font-family: Poppins, sans-serif;
      font-size: 14px;
    }
    .col-right {
      display: flex;
      color: rgba(0, 0, 0, 0.7);
      font-size: 15px;
      line-height: 1.73;
      font-weight: 600;
    }
    i {
      margin-right: 12px;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.7);
    }
    .file-title {
      margin: auto 0;
    }
  }
  @include mobile-tablet {
    .main-content {
      margin-bottom: 8px;
    }
    .select-box-container {
      margin: 0 auto 83px 30px;
    }
    .select-box {
      width: 120px;
    }
    .item {
      border-top: solid 1px;
      border-color: rgba(0, 0, 0, 0.05);
    }
    .col-left {
      margin-top: 29px;
      color: rgba(0, 0, 0, 0.4);
      font-family: Poppins, sans-serif;
      font-size: 14px;
    }
    .col-right {
      color: rgba(0, 0, 0, 0.8);
      line-height: 1.73;
      font-weight: 600;
    }
    .file-title {
      font-size: 14px;
      margin-top: 15px;
    }
    i {
      font-size: 22px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

#ir-stock-price {
  .link-btn {
    border-radius: 50%;
    width: 44px;
    i {
      color: white;
      font-size: 24px;
      line-height: 44px;
    }
  }
  .text-block {
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
  }
  @include pc {
    margin: 40px 0 120px;
    .link-wrapper {
      display: flex;
      margin: 15px auto 0;
      text-align: center;
      width: 441px;
    }
    .link-btn {
      flex-shrink: 0;
      margin-right: 20px;
    }
    .text-block {
      margin: auto 0;
    }
  }
  @include mobile-tablet {
    .main-content {
      margin-bottom: 120px;
    }
    .link-btn {
      margin: 0 auto;
    }
    .text-block {
      text-align: center;
      margin: 28px 20px 0;
    }
  }
}

#ir-calendar {
  .col-right {
    color: rgba(0, 0, 0, 0.7);
    font-size: 15px;
    line-height: 1.73;
    font-weight: 600;
    display: flex;
  }
  i {
    color: rgba(0, 0, 0, 0.7);
  }
  .col-left {
    color: rgba(0, 0, 0, 0.4);
    font-family:  Poppins, sans-serif;
    font-size: 14px;
  }
  .calendar-image {
    margin-bottom: 30px;
  }
  .calendar-text {
    margin: 60px auto 120px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
  }
  @include pc {
    .main-content {
      width: 800px;
    }
    .calendar-image {
      width: 100%;
    }
    .item {
      display: flex;
      padding: 34px 0;
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    }
    .col-left {
      margin: auto 30px auto 0;
    }
    i {
      margin-right: 12px;
      font-size: 18px;
    }
    .calendar-text {
      width: 600px;
    }
    .file-title {
      margin: auto 0;
    }
    @media screen and (max-width: 1250px) {
      .items,
      .calendar-text {
        width: 80%;
      }
      .items {
        margin: 0 auto;
      }
    }
  }
  @include mobile-tablet {
    .main-content {
      max-width: 600px;
      margin-bottom: 30px;
    }
    .calendar-image {
      width: 100%;
    }
    .item {
      padding: 20px 0;
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    }
    .col-left {
      margin-bottom: 10px;
    }
    i {
      margin-top: -3px;
      margin-right: 10px;
      font-size: 17px;
    }
  }
}

#ir-library {
  .disclaimer {
    @include pc {
      margin-top: 80px;
      width: 600px;
      height: 144px;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.85;
      letter-spacing: 0.9px;
      color: rgba(0, 0, 0, 0.6);
      @media screen and (max-width: 1250px) {
        width: 100%;
      }
    }

    @include mobile-tablet {
      margin: 80px 0 120px;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.85;
      color: rgba(0, 0, 0, 0.6);
    }

    a {
      font-weight: 600;
      color: #22b0d3;
    }
  }
}

#ir-public-notice {
  @include mobile-tablet {
    .lead {
      padding-top: 60px;
    }
    .main-content {
      min-height: 300px;
    }
  }
}
